/** @format */

import React, { useState, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"

// import { selectLocationsForChat } from "../../features/locations/locations-slice"

const Image = ({ args }) => {
  const src = `https://wa.klipstore.com/downloads/${args.id.remote}/${args.id.id}.${
    args._data.mimetype ? args._data.mimetype.split("/")[1] : ""
  }`
  return (
    <div className={`Message ${args.fromMe ? "mine" : ""}`}>
      <figure className="figure mb-0">
        {args.fromMe ? (
          <img
            src={"data:image/png;base64," + args._data.body}
            style={{ maxWidth: "200px" }}
            className="figure-img img-fluid rounded mb-0"
          />
        ) : (
          <a href={src} target="_blank">
            <img src={src} style={{ maxWidth: "200px" }} className="figure-img img-fluid rounded mb-0" />
          </a>
        )}
        {args._data.mimetype && ["mp4", "mov"].indexOf(args._data.mimetype.split("/")[1]) >= 0 && (
          <small className="mb-0 font-weight-bold">[VIDEO]</small>
        )}
      </figure>
    </div>
  )
}

export default Image
