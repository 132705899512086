/** @format */

import React, { useState, useEffect, useRef, useCallback, useMemo } from "react"
import { useForm, useWatch, useFieldArray, useFormContext } from "react-hook-form"
import { useSelector, useDispatch } from "react-redux"
import PropTypes from "prop-types"

import SalesService from "../services/klipsales.service.js"
import Guaranies, { formatter } from "../components/Guaranies"
import { fetchLocations, selectLocationsForChat } from "../features/locations/locations-slice"
import {
  fetchOrders,
  selectOrdersForChat,
  createOrder,
  updateOrder,
  selectOrderById,
  voidOrder,
  confirmOrder,
  selectOrderTotals,
} from "../features/orders/orders-slice"
import { selectAllProducts } from "../features/products/products-slice"
import OrdersList from "../components/OrdersList"

const ShippingSection = ({ control, products, chatId, handleSendSummary, deployed, onAdvance }) => {
  const [visible, setVisible] = useState(deployed)
  const [shippingCompanies, setShippingCompanies] = useState([])
  const [shippingRate, setShippingRate] = useState(null)
  const [cityFilter, setCityFilter] = useState("")
  const [showLocationsList, setShowLocationsList] = useState(false)
  const [results, setResults] = useState([])
  const [fetchingResults, setFetchingResults] = useState(false)
  const [shippingCompanyName, setShippingCompanyName] = useState("")
  const { watch, register, setValue } = useFormContext() // retrieve all hook methods
  const locations = useSelector(state => selectLocationsForChat(state, chatId))
  const shipping_type = watch("shipping_type")
  const maps_url = watch("maps_url")
  const shipping_company_id = watch("shipping_company_id")
  const city = watch("city")
  const shipping_price = watch("shipping_price")
  const getResults = async val => {
    if (val) {
      setFetchingResults(true)
      const r = await SalesService.listCities(val).catch(e => console.log(e))
      if (r) {
        setFetchingResults(false)
        setResults(r)
      }
    }
  }

  useEffect(() => {
    SalesService.listShippingCompanies().then(results => {
      setShippingCompanies(results)
    })
  }, [])

  useEffect(() => {
    getResults(cityFilter)
  }, [cityFilter])

  useEffect(() => {
    if ((!shippingCompanyName || shippingCompanyName.length == 0) && shipping_company_id) {
      SalesService.showShippingCompany(shipping_company_id).then(company => {
        if (company) {
          setShippingCompanyName(company.name)
        }
      })
    }
    if (!shipping_company_id) {
      setShippingCompanyName("")
    }
  }, [shippingCompanyName, shipping_company_id])

  const resetData = type => {
    if (type) {
      setValue("shipping_type", type)
    } else {
      setValue("shipping_type", "")
    }
    setShippingRate(null)
    setValue("shipping_price", 0)
    setValue("shipping_company_id", null)
    setValue("shipping_address", "")
    setValue("maps_url", "")
    setValue("address", "")
    setValue("city", "")
    setShippingCompanyName("")
  }
  return (
    <div className="card border-light">
      <div
        className="card-header bg-light text-uppercase font-weight-bold cursor-pointer d-flex justify-content-between"
        onClick={() => setVisible(!visible)}
      >
        {!shipping_type && "ENTREGA"}
        {shipping_type && (
          <div>
            {shipping_type == "local" && "Delivery"}
            {shipping_type == "remote" && "Encomienda"}
            {shipping_type == "pickup" && "Pasa a retirar"}
            <small className="text-secondary ml-2">
              <i>{city}</i>
            </small>
          </div>
        )}
        <div className="">{shipping_type ? "✅" : "⬜️"}</div>
      </div>
      <div className={`card-body ${deployed || visible ? "" : "d-none"}`}>
        <div className="btn-group w-100 mb-2">
          <button
            type="button"
            className={`btn btn-${shipping_type === "pickup" ? "primary" : "default"} btn-lg`}
            onClick={() => {
              resetData("pickup")
            }}
          >
            <span className="h4 d-block" style={{ transform: "scale(-1, 1)" }}>
              🚶‍♂️
            </span>
          </button>
          <button
            type="button"
            className={`btn btn-${shipping_type === "local" ? "primary" : "default"} btn-lg`}
            onClick={() => resetData("local")}
          >
            <span className="h4">🛵</span>
          </button>
          <button
            type="button"
            className={`btn btn-${shipping_type === "remote" ? "primary" : "default"} btn-lg`}
            onClick={() => resetData("remote")}
          >
            <span className="h4">🚚</span>
          </button>
        </div>
        {shipping_type === "pickup" && (
          <div>
            <a
              href={"#"}
              onClick={() => {
                handleSendSummary(
                  "Estamos en:\n\n📍 Asunción: Klip! Charles de Gaulle y Hassler 👉 https://goo.gl/maps/xqmfNr4wNxMTB5x3A Lunes a Sábado 10 a 18 Hs"
                )
              }}
            >
              <small>
                <u>Enviar ubicación y horarios</u>
              </small>
            </a>
          </div>
        )}
        {["local", "remote"].indexOf(shipping_type) >= 0 && (
          <div>
            {!city && (
              <div className="input-group mb-3">
                <input
                  value={cityFilter}
                  onChange={e => setCityFilter(e.target.value)}
                  className="form-control border-warning"
                  autoComplete="off"
                  placeholder="Ciudad"
                  autoFocus
                  onFocus={e => e.currentTarget.select()}
                />
              </div>
            )}

            {city && (
              <div className="input-group mb-3">
                <input
                  className="form-control border-primary"
                  placeholder="Ciudad"
                  {...register("city")}
                  disabled
                />
                <div className="input-group-append">
                  <button
                    className="btn btn-danger"
                    type="button"
                    onClick={() => {
                      setCityFilter("")
                      resetData(shipping_type)
                    }}
                  >
                    X
                  </button>
                </div>
              </div>
            )}

            <div className="form-group">
              {fetchingResults && (
                <div className="text-right">
                  <small className="text-secondary">
                    <i>Buscando tarifas...</i>
                  </small>
                </div>
              )}
              {!fetchingResults && !city && cityFilter.length > 0 && results.length == 0 && (
                <a
                  href="#"
                  onClick={() => {
                    setValue("city", cityFilter)
                    setCityFilter("")
                    setResults([])
                  }}
                >
                  Agregar "{cityFilter}"
                </a>
              )}
              {cityFilter.length > 0 &&
                results.map((result, i) => (
                  <div key={`rate_${i}_${result.id}`}>
                    {
                      <div className={`d-flex justify-content-${city.length == 0 ? "between" : "end"}`}>
                        {city.length == 0 && (
                          <a
                            href="#"
                            onClick={() => {
                              setValue("city", result.name)
                              setCityFilter("")
                              setResults([])
                            }}
                          >
                            <b>{result.name}</b>
                          </a>
                        )}

                        <small className="text-secondary">
                          <i>Tarifas</i>
                        </small>
                      </div>
                    }
                    <div className="text-right">
                      {result.shipping_rates.length == 0 && <small>No se encontraron tarifas</small>}
                      {result.shipping_rates.map(rate => {
                        return (
                          <div key={`rate_${rate.id}`} className="d-flex flex-grow-1 justify-content-end">
                            <a
                              href="#"
                              onClick={() => {
                                setValue("shipping_price", rate.price)
                                setValue("shipping_company_id", rate.shipping_company.id)
                                setValue("city", result.name)
                                setShippingCompanyName(rate.shipping_company.name)
                                setCityFilter("")
                                setShippingRate(rate)
                                setResults([])
                              }}
                            >
                              <small>
                                {rate.price > 0 ? <Guaranies>{rate.price}</Guaranies> : "GRATIS"} con{" "}
                                {rate.shipping_company.name}
                              </small>
                            </a>
                            <div className="ml-4">
                              <div
                                role="button"
                                className=""
                                onClick={() => {
                                  if (shipping_type === "local") {
                                    if (rate.price === 0) {
                                      handleSendSummary(
                                        "El delivery te sale gratis confirmando el pedido hoy ☺️"
                                      )
                                    } else {
                                      handleSendSummary(
                                        `El delivery hasta ${result.name} te sale ${formatter.format(
                                          rate.price
                                        )}`
                                      )
                                    }
                                  } else {
                                    handleSendSummary(
                                      `*${formatter.format(
                                        rate.price
                                      )}* sería el costo de envío 😊 \n\nTrabajamos con la transportadora _${
                                        rate.shipping_company.name
                                      }_.\n\n*Para confirmar* necesitamos tu nombre completo y comprobante de pago.\n\nSi confirmás *antes de las 15hs* entre semana despachamos el paquete en el día. Al día siguiente se comunican contigo desde ${
                                        rate.shipping_company.name
                                      } para coordinar horario y lugar exacto de entrega 🥳`
                                    )
                                  }
                                }}
                              >
                                ✉️
                              </div>
                            </div>
                          </div>
                        )
                      })}
                    </div>
                  </div>
                ))}
            </div>
            <hr />
            <div>
              {!city && locations.length > 0 && (
                <div className="form-group text-right">
                  <div className="text-secondary">
                    <small>
                      <i>Ubicaciones del cliente</i>
                    </small>
                  </div>
                  <ul className="list-unstyled">
                    {locations.map((l, i) => {
                      const cityName = l.cityName.replace(", Paraguay", "")
                      return (
                        <li
                          key={`userlocationsl_${i}_${l.coordinates.latitude}_${l.coordinates.longitude}`}
                          className="list-group-itemm list-group-item-actionn text-primary cursor-pointer"
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            setCityFilter(cityName)
                            setShowLocationsList(false)
                            setShippingCompanyName("")
                            setValue("shipping_company_id", null)
                            setValue("city", cityName)
                            setValue(
                              "maps_url",
                              `https://maps.google.com/?q=${l.coordinates.latitude},${l.coordinates.longitude}`
                            )
                          }}
                        >
                          <a href={"#"}>
                            <small>📍{cityName}</small>
                          </a>
                        </li>
                      )
                    })}
                  </ul>
                </div>
              )}
              {city && (
                <div>
                  {shipping_type == "remote" && (
                    <div className="form-group">
                      <div className="form-row">
                        <div className="col">
                          <label>Forma de pago</label>
                          <select
                            className="form-control form-control-sm"
                            onChange={e => {
                              if (e.target.value === "prepaid") {
                                if (shippingRate) {
                                  setValue("shipping_price", shippingRate.price)
                                }
                              } else if (e.target.value === "upon_receipt") {
                                setValue("shipping_price", 0)
                              }
                            }}
                          >
                            <option value="prepaid">Contado</option>
                            <option value="upon_receipt">Al recibir</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="form-group">
                    <div className="form-row">
                      <div className="col">
                        <label>Courier</label>
                        <select
                          className="form-control form-control-sm"
                          onChange={e => {
                            setValue("shipping_company_id", parseInt(e.target.value))
                          }}
                          value={shipping_company_id}
                          // {...register("shipping_company_id", {
                          //   setValueAs: v => (v === "" ? null : parseInt(v)),
                          // })}
                        >
                          <option value={null}>--</option>
                          {shippingCompanies
                            .filter(c => c.active)
                            .map(c => (
                              <option key={`sc_${c.id}`} value={c.id}>
                                {c.name}
                              </option>
                            ))}
                        </select>
                      </div>
                      <div className="col">
                        <label>Precio</label>
                        <input
                          {...register("shipping_price")}
                          placeholder="Precio"
                          type="number"
                          className="form-control form-control-sm"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <label>Ubicación</label>
                    <input
                      {...register("maps_url")}
                      placeholder="Link Google map"
                      className="form-control form-control-sm"
                    />
                    {city &&
                      maps_url.length == 0 &&
                      locations.filter(l => l.cityName.includes(city)).length > 0 && (
                        <div className=" mt-2 text-right">
                          <small className="text-secondary">
                            <i>Ubicaciones sugeridas</i>
                          </small>
                          <ul className="list-unstyled">
                            {locations
                              .filter(l => l.cityName.includes(city))
                              .map((l, i) => {
                                const cityName = l.cityName.replace(", Paraguay", "")
                                return (
                                  <li
                                    key={`userlocationsl_${i}_${l.coordinates.latitude}_${l.coordinates.longitude}`}
                                    className="list-group-itemm list-group-item-actionn text-primary cursor-pointer"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      setCityFilter(cityName)
                                      setShowLocationsList(false)
                                      setShippingCompanyName("")
                                      setValue("shipping_company_id", null)
                                      setValue("city", cityName)
                                      setValue(
                                        "maps_url",
                                        `https://maps.google.com/?q=${l.coordinates.latitude},${l.coordinates.longitude}`
                                      )
                                    }}
                                  >
                                    <a href={"#"}>
                                      <small>📍{cityName}</small>
                                    </a>
                                  </li>
                                )
                              })}
                          </ul>
                        </div>
                      )}
                  </div>
                  <div className="form-group">
                    <label>Dirección</label>
                    <input
                      {...register("address")}
                      placeholder="Dirección"
                      className="form-control form-control-sm"
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
        {["remote", "local"].indexOf(shipping_type) >= 0 && shipping_company_id && city && (
          <div>
            <div className="form-group">
              <label>Indicaciones</label>
              <textarea
                {...register("shipping_notes")}
                placeholder="Entregar sin avisar, tocar timbre, etc."
                className="form-control form-control-sm"
              ></textarea>
            </div>
            <h5>Recibe otra persona?</h5>
            <div className="form-group">
              <label>Nombre de quien recibe</label>
              <input type="text" {...register("recipient_name")} className="form-control form-control-sm" />
            </div>
            <div className="form-group">
              <label>Teléfono</label>
              <input type="text" {...register("recipient_phone")} className="form-control form-control-sm" />
            </div>
          </div>
        )}
      </div>
      <div
        className={`card-footer text-muted justify-content-between ${
          deployed || visible ? "d-flex" : "d-none"
        }`}
      >
        {shipping_type ? (
          <a
            href={"#"}
            className="card-link text-secondary text-decoration-underline"
            onClick={() => {
              resetData()
            }}
          >
            <small>
              <u>Cancelar</u>
            </small>
          </a>
        ) : (
          <div />
        )}
        <button
          type="button"
          className={`btn btn-success btn-sm ${!shipping_type && "disabled"}`}
          onClick={() => {
            setVisible(false)
            onAdvance()
          }}
          disabled={!shipping_type}
        >
          Listo
        </button>
      </div>
    </div>
  )
}

export default ShippingSection
