/** @format */

import { createSlice, createEntityAdapter, nanoid, createAsyncThunk, createSelector } from "@reduxjs/toolkit"

import WhatsAppService from "../../services/whatsapp.service.js"

const locationsAdapter = createEntityAdapter({
  selectId: message => message.id._serialized,
  sortComparer: (a, b) => a.timestamp - b.timestamp,
})

const initialState = locationsAdapter.getInitialState({
  status: "idle",
  error: null,
})

const locationsSlice = createSlice({
  name: "locations",
  initialState,
  reducers: {
    // receive
    received: (state, action) => {
      state[action.payload.userid] = [action.payload.location]
    },
  },
  extraReducers(builder) {
    builder
      .addCase("@@INIT", (state, action) => {
        state.status = "idle"
      })
      .addCase(fetchLocations.pending, (state, action) => {
        state.status = "loading"
      })
      .addCase(fetchLocations.fulfilled, (state, action) => {
        state.status = "succeeded"
        locationsAdapter.upsertMany(state, action.payload)
        action.payload.map(m => {
          // console.log(m.body)
        })
      })
      .addCase(fetchLocations.rejected, (state, action) => {
        state.status = "failed"
        state.error = action.error.message
      })
  },
})

export const fetchLocations = createAsyncThunk(
  "locations/fetchLocations",
  async ({ chatId, page = 1 }, thunkAPI) => {
    const response = await WhatsAppService.listLocations(chatId, page)
    if (response.length) {
      thunkAPI.dispatch(fetchLocations({ chatId, page: page + 1 }))
    }
    return response
  }
)

export const {
  selectAll: selectAllLocations,
  // selectById: selectChatById,
  // selectIds: selectMessageIds,
  // Pass in a selector that returns the posts slice of state,
} = locationsAdapter.getSelectors(state => state.locations)

export const selectLocationsForChat = createSelector(
  [selectAllLocations, (state, userId) => userId],
  (items, userId) => {
    const filtered = items.filter(location => {
      if (typeof location.id.remote === "string") {
        return location.id.remote === userId
      } else {
        return location._k_chatid === userId
      }
    })
    return filtered
  }
)
export const { received } = locationsSlice.actions
export default locationsSlice.reducer
