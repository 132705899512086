/** @format */

import React, { useState, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"

import { selectLocationsForChat } from "../../features/locations/locations-slice"

const Location = ({ args }) => {
  const city = useSelector(state => selectLocationsForChat(state, args.id.remote)).find(
    l => l.id.id == args.id.id
  )
  return (
    <div className={`Message ${args.fromMe ? "mine" : ""}`}>
      <a
        href={`https://maps.google.com/?q=${args.location.latitude},${args.location.longitude}`}
        target="_blank"
        className="d-flex flex-column"
      >
        <img className="mb-1" src={"data:image/png;base64," + args._data.body} />
        <span>📍{city && city.cityName}</span>
      </a>
    </div>
  )
}

export default Location
