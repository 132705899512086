/** @format */

import React, { useState, useEffect, useRef, useCallback, useMemo } from "react"

const StatusToEmoji = ({ status }) => {
  switch (status) {
    case "draft":
      return "🛒"
    case "confirmed":
      return "⚠️"
    case "processing":
      return "⚙️"
    case "ready":
      return "📦"
    case "shipped":
      return "✈️"
    case "delivered":
      return "✅"
    case "cancelled":
      return "💀"
    default:
      return status
  }
}

export default StatusToEmoji
