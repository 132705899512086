/** @format */

import React, { useState, useEffect, useRef, useCallback, useMemo } from "react"
import { useForm, useWatch, useFieldArray } from "react-hook-form"
import { useSelector, useDispatch } from "react-redux"
import PropTypes from "prop-types"

import {
  fetchOrders,
  selectOrdersForChat,
  createOrder,
  updateOrder,
  selectOrderById,
  voidOrder,
  confirmOrder,
  selectOrderTotals,
} from "../features/orders/orders-slice"
import Guaranies from "./Guaranies"
import StatusToEmoji from "./StatusToEmoji"

const OrdersList = ({ phone, handleViewOrder, canCreateOrder, handleNewOrder }) => {
  const orders = useSelector(state => selectOrdersForChat(state, phone))
  const cancelledOrders = orders.filter(o => o.status === "cancelled")
  const draftOrder = orders.find(o => o.status === "draft")
  const activeOrder = orders.find(o => ["confirmed", "processing", "ready", "shipped"].indexOf(o.status) >= 0)
  const finishedOrders = orders.filter(o => o.status === "delivered")
  return (
    <div>
      {canCreateOrder && (
        <div className="card bg-dark mb-3">
          <div className="card-body">
            <button className="btn btn-primary btn-lg btn-block" onClick={() => handleNewOrder()}>
              NUEVO PEDIDO
            </button>
          </div>
        </div>
      )}
      {activeOrder && (
        <div className="card bg-success mb-3">
          <div className="card-header border-bottom-0 bg-success text-uppercase font-weight-bold d-flex justify-content-between align-items-center">
            <div>
              <StatusToEmoji status={activeOrder.status} />#{activeOrder.number}
            </div>
            <div>
              <small>
                <Guaranies>{activeOrder.grand_total}</Guaranies>
              </small>
            </div>
            <div>
              <a
                className="btn btn-dark btn-block font-weight-bold btn-sm"
                onClick={() => handleViewOrder(activeOrder.id)}
              >
                Ver
              </a>
            </div>
          </div>
        </div>
      )}
      {draftOrder && (
        <div className="card bg-primary mb-3">
          <div className="card-header text-center font-weight-bold">Pedido pendiente</div>
          <div className="card-body text-center">
            <h5 className="card-title">#{draftOrder.number}</h5>
            <a className="btn btn-light btn-block" onClick={() => handleViewOrder(draftOrder.id)}>
              CONTINUAR
            </a>
          </div>
        </div>
      )}
      {finishedOrders.length > 0 && (
        <div>
          <h5>Pedidos entregados ({finishedOrders.length})</h5>
          <div className="list-group">
            {finishedOrders.map(o => (
              <a
                className="list-group-item list-group-item-action"
                onClick={() => handleViewOrder(o.id)}
                key={`finished_orders_${o.id}`}
              >
                Order #{o.number}
              </a>
            ))}
          </div>
        </div>
      )}
    </div>
  )
}

export default OrdersList
