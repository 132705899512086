/** @format */

var fulldays = ["Domingo", "Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado"]
var months = ["Ene", "Feb", "Mar", "Abr", "May", "Jun", "Jul", "Ago", "Sep", "Oct", "Nov", "Dic"]

function formatDate({ date, useTimeForPastDates = false }) {
  var dt = new Date(date),
    _date = dt.getDate(),
    month = months[dt.getMonth()],
    timeDiff = date - Date.now(),
    diffDays = new Date().getDate() - _date,
    diffMonths = new Date().getMonth() - dt.getMonth(),
    diffYears = new Date().getFullYear() - dt.getFullYear()

  var append = ""
  if (useTimeForPastDates) {
    append = ` ${String(dt.getHours()).padStart(2, "0")}:${String(dt.getMinutes()).padStart(2, "0")}`
  }

  if (diffYears === 0 && diffDays === 0 && diffMonths === 0) {
    return `${String(dt.getHours()).padStart(2, "0")}:${String(dt.getMinutes()).padStart(2, "0")}`
  } else if (diffYears === 0 && diffDays === 1) {
    return "Ayer" + append
  } else if (diffYears === 0 && diffDays > 1 && diffDays < 7) {
    return fulldays[dt.getDay()] + append
  } else if (diffYears === 0 && diffDays === -1) {
    return "Mañana" + append
  } else if (diffYears === 0 && diffDays < -1 && diffDays > -7) {
    return fulldays[dt.getDay()] + append
  } else if (diffYears >= 1) {
    return month + " " + _date + ", " + new Date(date).getFullYear() + append
  } else {
    return month + " " + _date + append
  }
}

export default formatDate
