/** @format */

import React, { useState, useEffect, useRef, useCallback } from "react"
import QRCode from "react-qr-code"
import { useSelector, useDispatch } from "react-redux"
import ScrollToBottom from "react-scroll-to-bottom"
import useWebSocket, { ReadyState } from "react-use-websocket"

import SalesService from "./services/klipsales.service.js"
import Workspace from "./Workspace"
import Login from "./Login"
import "./App.css"

const App = ({ handleTokenReceived, storeEmail }) => {
  const [token, setToken] = useState(null)
  const [email, setEmail] = useState("")
  const [error, setError] = useState("")
  const [pass, setPass] = useState("")
  const submit = async e => {
    e.preventDefault()
    try {
      const result = await SalesService.login({ email, password: pass })
      console.log("result!!!", result)
      if (result && result.success) {
        storeEmail(result.profile_pic_url)
        handleTokenReceived(result.auth_token)
      } else {
      }
    } catch (e) {
      setError(e.error)
    }
    window.location.reload()
  }
  return (
    <div className="">
      <form onSubmit={submit}>
        <input
          className="form-control"
          type="email"
          placeholder="email"
          value={email}
          onChange={e => setEmail(e.target.value)}
        />
        <input
          className="form-control"
          type="password"
          placeholder="contraseña"
          value={pass}
          onChange={e => setPass(e.target.value)}
        />
        <button className="btn btn-primary">Ingresar</button>
        <p>{error}</p>
      </form>
    </div>
  )
}

export default App
