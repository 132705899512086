/** @format */

import React, { useState, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"

// import { selectLocationsForChat } from "../../features/locations/locations-slice"

const Audio = ({ args }) => {
  return (
    <div className={`Message ${args.fromMe ? "mine" : ""}`}>
      <audio controls>
        <source
          src={`http://wa.klipstore.com/downloads/${args.id.remote}/${args.id.id}.${
            args._data.mimetype ? args._data.mimetype.split("/")[1].split(";")[0] : ""
          }`}
          type="audio/ogg"
        />
        Your browser does not support the audio element.
      </audio>
    </div>
  )
}

export default Audio
