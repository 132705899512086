/** @format */
import axios from "axios"

const getToken = () => {
  return localStorage.getItem("token")
  const tokenString = localStorage.getItem("token")
}

// const _db_http = axios.create({
//   baseURL: "http://klipsales.local:3000/",
//   headers: {
//     "Content-type": "application/json",
//     Authorization: "Bearer " + getToken(),
//   },
// })
const _db_http = axios.create({
  // baseURL: "http://klipsales.local:3000/",
  // baseURL: "http://wa.klipstore.com:5001/",
  baseURL: "https://sales.klipstore.com/",
  headers: {
    "Content-type": "application/json",
    Authorization: "Bearer WJznS8wKTvfStHmFqmsD",
  },
})

_db_http.interceptors.response.use(
  function (response) {
    // Do something with response data
    return response.data
  },
  function (error) {
    console.log("AXIOS ERR")
    if (error.response) {
      console.log("AAARRRR")
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      console.log(error.response.data)
      console.log(error.response.status)
      console.log(error.response.headers)
    } else if (error.request) {
      console.log("BBBRRRR")
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      console.log(error.request)
    } else {
      console.log("CCCCCCC")
      // Something happened in setting up the request that triggered an Error
      console.log("Error", error.message)
    }
    console.log(error.config)
    // return error?.response?.data
    console.log("will reject with", error)
    return Promise.reject(error?.response?.data)
    // return Promise.reject("MOTIVITO")
  }
)

class SalesService {
  listReplies(page = 1) {
    return _db_http.get(`/replies.json`, { params: { page } }) //.then(res => res.data)
  }

  login({ email, password }) {
    return _db_http.post(`/users/sign_in.json`, { user: { email, password } })
  }

  bumpReplySendCount(id) {
    return _db_http.post(`/replies/${id}/bump_send_count.json`) //.then(res => res.data)
  }

  listCities(query, page = 1) {
    if (this.controller) {
      this.controller.abort()
    }
    this.controller = new AbortController()
    const signal = this.controller.signal
    return _db_http.get(`/cities.json?by_keyword=${query}`, { params: { page }, signal })
    //.then(res => res.data)
  }

  showShippingCompany(id) {
    // return _db_http.get(`/orders/${page}`).then(res => res.data)
    return _db_http.get(`/shipping_companies/${id}.json`) //.then(res => res.data)
  }

  listShippingCompanies() {
    return _db_http.get(`/shipping_companies.json`) //.then(res => res.data)
  }

  listProducts(page = 1) {
    // return _db_http.get(`/orders/${page}`).then(res => res.data)
    return _db_http.get(`/products.json`, { params: { page } }) //.then(res => res.data)
  }

  bumpProductWAViewCount(id) {
    // return _db_http.get(`/orders/${page}`).then(res => res.data)
    return _db_http.post(`/products/${id}/bump_whatsapp_views_count.json`) //.then(res => res.data)
  }

  listOrders(phone, page = 1) {
    // return _db_http.get(`/orders/${page}`).then(res => res.data)
    return _db_http.get(`/orders.json`, { params: { phone, page } }) //.then(res => res.data)
  }

  listOrdersById(page = 1) {
    return _db_http.get(`/orders/by_user_id.json`, { params: { page } }) //.then(res => res.data)
  }

  // listMessages(chatId, page = 1) {
  //   // return []
  //   // return http.get(`/chats/${chatId}/messages`).then(res => res.data)
  //   return _db_http.get(`/chats/${chatId}/messages/${page}`).then(res => res.data)
  // }

  // listLocations(chatId, page = 1) {
  //   return _db_http.get(`/chats/${chatId}/locations/${page}`).then(res => res.data)
  // }

  updateOrder(id, payload) {
    return _db_http.patch(`/orders/${id}.json`, payload) //then(res => res.data)
  }

  createOrder(payload) {
    return _db_http.post(`/orders.json`, payload) //.then(res => res.data)
  }

  voidOrder(id, payload) {
    console.log("VOID order", id, payload)
    return _db_http.post(`/orders/${id}/mark_as_cancelled.json`, payload) //.then(res => res.data)
  }

  confirmOrder(id) {
    console.log("CONFIRM order", id)
    return _db_http.post(`/orders/${id}/mark_as_confirmed.json`) //.then(res => res.data)
  }

  // sendImage(chatId, content) {
  //   return http.post(`/chats/${chatId}/sendImage`, { content: content }).then(res => res.data)
  // }

  // show(id) {
  //   return http.get(`/feed_blueprints/${id}` + alwaysAppendQs).then(res => res.data["response"])
  // }
}

export default new SalesService()
