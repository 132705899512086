/** @format */

import React, { useState, useEffect } from "react"
import ScrollToBottom from "react-scroll-to-bottom"
import { useSelector, useDispatch } from "react-redux"

import {
  fetchMessages,
  selectAllMessages,
  selectMessagesForChat,
  messageAdded,
} from "./features/messages/messages-slice"

import Message from "./Message"
import formatDate from "./lib/formatDate"

const ChatBox = ({ chatId }) => {
  const messages = useSelector(state => selectMessagesForChat(state, chatId))
  // console.log("messages?", messages.length, messages[messages.length - 1])
  return (
    <div>
      <ScrollToBottom
        initialScrollBehavior="smooth"
        className="MessageBox"
        scrollViewClassName="MessageBoxScroll"
      >
        {messages.map(message => (
          <Message key={message.id.id} {...message} />
        ))}
      </ScrollToBottom>
    </div>
  )
}

export default ChatBox
