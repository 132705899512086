/** @format */

import React, { useState, useEffect, useRef, useCallback } from "react"
import QRCode from "react-qr-code"
import { useSelector, useDispatch } from "react-redux"
import ScrollToBottom from "react-scroll-to-bottom"
import useWebSocket, { ReadyState } from "react-use-websocket"

import Workspace from "./Workspace"
import Login from "./Login"
import "./App.css"

const setToken = token => {
  localStorage.setItem("token", token)
  // window.location.reload()
}
const setEmail = email => {
  localStorage.setItem("profile_pic_url", email)
  // window.location.reload()
}

const getToken = () => {
  return localStorage.getItem("token")
}

const App = ({ client }) => {
  const token = getToken()
  if (!token) {
    return <Login storeEmail={setEmail} handleTokenReceived={setToken} />
  }
  return <Workspace />
}

export default App
