/** @format */

import { createSlice, createEntityAdapter, nanoid, createAsyncThunk, createSelector } from "@reduxjs/toolkit"
import WhatsAppService from "../../services/whatsapp.service.js"
import { fetchLocations } from "../locations/locations-slice"
// import { chatUpdated } from "../chats/chats-slice"

const messagesAdapter = createEntityAdapter({
  selectId: message => message.id._serialized,
  sortComparer: (a, b) => a.timestamp - b.timestamp,
})

const initialState = messagesAdapter.getInitialState({
  status: "idle",
  error: null,
})

export const fetchMessages = createAsyncThunk(
  "messages/fetchMessages",
  async ({ chatId, page = 1 }, thunkAPI) => {
    const response = await WhatsAppService.listMessages(chatId, page)
    let did = false
    response.map(m => {
      if (m.type === "location" && !did) {
        did = true
        thunkAPI.dispatch(
          fetchLocations({
            chatId: m.from,
          })
        )
      }
    })
    if (response.length && page < 2) {
      thunkAPI.dispatch(fetchMessages({ chatId, page: page + 1 }))
    }
    return response
  }
)

const messagesSlice = createSlice({
  name: "messages",
  initialState,
  reducers: {
    messageAdded: messagesAdapter.addOne,
  },
  extraReducers(builder) {
    builder
      .addCase("@@INIT", (state, action) => {
        state.status = "idle"
      })
      .addCase(fetchMessages.pending, (state, action) => {
        state.status = "loading"
      })
      .addCase(fetchMessages.fulfilled, (state, action) => {
        state.status = "succeeded"
        messagesAdapter.upsertMany(state, action.payload)
        action.payload.map(m => {
          // console.log(m.body)
        })
      })
      .addCase(fetchMessages.rejected, (state, action) => {
        state.status = "failed"
        state.error = action.error.message
      })
  },
})

export const { messageAdded } = messagesSlice.actions

export const {
  selectAll: selectAllMessages,
  // selectById: selectChatById,
  // selectIds: selectMessageIds,
  // Pass in a selector that returns the posts slice of state,
} = messagesAdapter.getSelectors(state => state.messages)

export const selectMessagesForChat = createSelector(
  [selectAllMessages, state => state.chats.accountFilterValue, (state, userId) => userId],
  (items, accountFilterValue, userId) => {
    return items
      .filter(message => {
        if (typeof message.id.remote === "string") {
          return message.id.remote === userId
        } else {
          return message.id.remote._serialized === userId
        }
      })
      .filter(c => (accountFilterValue ? c._k_client === accountFilterValue : c))
  }
)

export default messagesSlice.reducer
