/** @format */

import React, { useState, useEffect, useRef, useCallback } from "react"
import QRCode from "react-qr-code"
import { CopyToClipboard } from "react-copy-to-clipboard"
import { useSelector, useDispatch } from "react-redux"
import ScrollToBottom from "react-scroll-to-bottom"
import useWebSocket, { ReadyState } from "react-use-websocket"

import logo from "./logo.svg"
import WhatsAppService from "./services/whatsapp.service.js"
import { received as locationReceived, saveLocation } from "./features/locations/locations-slice"
import {
  fetchChats,
  chatUpdated,
  upsertOneChat,
  setFilterValue,
  upsertBasedOnNewMsg,
} from "./features/chats/chats-slice"
import { fetchLocations, selectLocationsForChat } from "./features/locations/locations-slice"
import { fetchProducts } from "./features/products/products-slice"
import {
  fetchOrders,
  selectOrdersForChat,
  fetchLightOrdersList,
  upsertOneOrder,
} from "./features/orders/orders-slice"
import {
  fetchMessages,
  selectAllMessages,
  selectMessagesForChat,
  messageAdded,
} from "./features/messages/messages-slice"
import ChatBox from "./ChatBox"
import Cart from "./Cart"
import Composer from "./Composer"
import ChatList from "./ChatList"
import messageToSnippet from "./lib/messageToSnippet"
import "./App.css"

const socketUrl = "wss://wa.klipstore.com:7071"

const App = ({ client }) => {
  const dispatch = useDispatch()
  const [chats, setChats] = useState([])
  const [qr, setQr] = useState()
  const [chat, setChat] = useState(null)
  const locations = useSelector(state => (chat ? selectLocationsForChat(state, chat.id._serialized) : []))
  const orders = useSelector(state => (chat ? selectOrdersForChat(state, chat.id.user) : []))
  const chatsLength = useSelector(state => state.chats.ids.length > 0)
  const chatsStatus = useSelector(state => state.chats.status)
  const productsStatus = useSelector(state => state.products.status)
  const statusFilter = useSelector(state => state.chats.filterValue)

  // WS

  const { sendMessage, lastMessage, readyState } = useWebSocket(socketUrl, {
    share: true,
    onOpen: () => console.log("websocket opened"),
    shouldReconnect: closeEvent => true,
  })

  useEffect(() => {
    try {
      if (lastMessage !== null) {
        if (lastMessage.data) {
          const b = JSON.parse(lastMessage.data)
          if (b.event_type === "order_upsert") {
            dispatch(upsertOneOrder(b.payload))
          }
          if (b.event_type === "message_create") {
            let tagChatWithLocation = false
            console.log("message_create", b.payload)
            dispatch(messageAdded(b.payload))
            let receivedLocationName = ""
            try {
              if (b.payload.type === "location") {
                tagChatWithLocation = true
                receivedLocationName = "Ubicación"
                dispatch(
                  fetchLocations({
                    chatId: b.payload.from,
                  })
                )
              }
            } catch (e) {
              console.log(e)
            }
            dispatch(upsertBasedOnNewMsg({ message: b.payload }))
          }
          if (b.event_type === "ack") {
            console.log("ack", b.payload)
            // dispatch(chatUpdated({ id: b.payload.msg.id.remote, changes: { _k_lastMsgAck: b.payload.ack } }))
            const changes = { _k_lastMsgAck: b.payload.ack }
            // if (b.payload.ack === 3) {
            //   changes:
            // }
            dispatch(
              chatUpdated({
                id: `${b.payload.msg.id.remote}_${b.payload.msg._k_client}`,
                changes: changes,
              })
            )
          }
        }
      }
    } catch (e) {
      console.log(e)
    }
  }, [lastMessage])

  const connectionStatus = {
    [ReadyState.CONNECTING]: "Connecting",
    [ReadyState.OPEN]: "Open",
    [ReadyState.CLOSING]: "Closing",
    [ReadyState.CLOSED]: "Closed",
    [ReadyState.UNINSTANTIATED]: "Uninstantiated",
  }[readyState]
  // WS

  useEffect(() => {
    if (chatsStatus === "idle") {
      dispatch(fetchChats())
    }
  }, [chatsStatus])

  useEffect(() => {
    // dispatch(fetchLightOrdersList())
  }, [])

  useEffect(() => {
    if (productsStatus === "idle") {
      dispatch(fetchProducts())
    }
  }, [productsStatus])

  const loadChats = async () => {
    const chats = await client.getChats()
    return chats
  }

  const setActiveChat = _chat => {
    setChat(_chat)
    dispatch(fetchMessages({ chatId: _chat.id._serialized }))
    dispatch(fetchOrders({ phone: _chat.id.user }))
    dispatch(fetchLocations({ chatId: _chat.id._serialized }))
  }

  const sendText = async content =>
    WhatsAppService.sendMessage(chat._k_client, chat.id._serialized, content).then(args => {
      console.log("sendText", args)
      const with_klip_index = { ...args, _k_chatid: args.id.remote, _k_client: chat._k_client }
      dispatch(messageAdded(with_klip_index))
      return
    })

  const sendImage = async content =>
    WhatsAppService.sendImage(chat._k_client, chat.id._serialized, content).then(args => {
      console.log("sendImage", args)
      const with_klip_index = { ...args, _k_chatid: args.id.remote, _k_client: chat._k_client }
      dispatch(messageAdded(with_klip_index))
      return
    })

  return (
    <div className="App">
      <div className="container-fluid p-0">
        <div className="row ">
          <div className="col-12 bg-dark text-white pt-2 pb-2" style={{ height: "70px" }}>
            <div className="col-12 d-flex flex-row justify-content-between align-items-center">
              <div className="d-flex align-items-center">
                <img
                  src="https://sales.klipstore.com/assets/header-logo-a58d2f36aed45279b2138c514f01539010d8461ebafbd9555eae2d0c9c2e912d.png"
                  style={{ width: "50px" }}
                  className="mr-4"
                />
                <div className="h4 mb-0">
                  {statusFilter == "" && "Todos los chats"}
                  {statusFilter == "location_without_order" && "Importantes"}
                  {statusFilter == "draft" && "Carritos"}
                  {statusFilter == "unread" && "No leídos"}
                  {statusFilter == "confirmed" && "Confirmados"}
                  {statusFilter == "processing" && "En producción"}
                  {statusFilter == "ready" && "Empacados"}
                  {statusFilter == "shipped" && "Despachados"}
                  {statusFilter == "delivered" && "Entregados"}
                  {statusFilter == "cancelled" && "Cancelados"}
                </div>
              </div>
              <div className="d-flex flex-row">
                <a
                  href="#"
                  className={`pl-3 pr-3 ${statusFilter == "" ? "bg-primary location_without_border" : ""}`}
                  onClick={() => dispatch(setFilterValue(""))}
                  style={{ fontSize: "2rem" }}
                >
                  🌏
                </a>
                <a
                  href="#"
                  className={`pl-3 pr-3 ${
                    statusFilter == "location_without_order" ? "bg-primary location_without_border" : ""
                  }`}
                  onClick={() => dispatch(setFilterValue("location_without_order"))}
                  style={{ fontSize: "2rem" }}
                >
                  🚨
                </a>
                <a
                  href="#"
                  className={`pl-3 pr-3 ${
                    statusFilter == "unread" ? "bg-primary location_without_border" : ""
                  }`}
                  onClick={() => dispatch(setFilterValue("unread"))}
                  style={{ fontSize: "2rem" }}
                >
                  🟢
                </a>
                <a
                  href="#"
                  className={`pl-3 pr-3 ${
                    statusFilter == "draft" ? "bg-primary location_without_border" : ""
                  }`}
                  onClick={() => dispatch(setFilterValue("draft"))}
                  style={{ fontSize: "2rem" }}
                >
                  🛒
                </a>
                <a
                  href="#"
                  className={`pl-3 pr-3 text-warning ${
                    statusFilter == "confirmed" ? "bg-primary location_without_border" : ""
                  }`}
                  onClick={() => dispatch(setFilterValue("confirmed"))}
                  style={{ fontSize: "2rem" }}
                >
                  ⚠️
                </a>
                <a
                  href="#"
                  className={`pl-3 pr-3 ${
                    statusFilter == "processing" ? "bg-primary location_without_border" : ""
                  }`}
                  onClick={() => dispatch(setFilterValue("processing"))}
                  style={{ fontSize: "2rem" }}
                >
                  ⚙️
                </a>
                <a
                  href="#"
                  className={`pl-3 pr-3 ${
                    statusFilter == "ready" ? "bg-primary location_without_border" : ""
                  }`}
                  onClick={() => dispatch(setFilterValue("ready"))}
                  style={{ fontSize: "2rem" }}
                >
                  📦
                </a>
                <a
                  href="#"
                  className={`pl-3 pr-3 text-white ${
                    statusFilter == "shipped" ? "bg-primary location_without_border" : ""
                  }`}
                  onClick={() => dispatch(setFilterValue("shipped"))}
                  style={{ fontSize: "2rem" }}
                >
                  ✈️
                </a>
                <a
                  href="#"
                  className={`pl-3 pr-3 ${
                    statusFilter == "delivered" ? "bg-primary location_without_border" : ""
                  }`}
                  onClick={() => dispatch(setFilterValue("delivered"))}
                  style={{ fontSize: "2rem" }}
                >
                  ✅
                </a>
                <a
                  href="#"
                  className={`pl-3 pr-3 ${
                    statusFilter == "cancelled" ? "bg-primary location_without_border" : ""
                  }`}
                  onClick={() => dispatch(setFilterValue("cancelled"))}
                  style={{ fontSize: "2rem" }}
                >
                  💀
                </a>
              </div>
              <div>
                <img
                  src={localStorage.getItem("profile_pic_url")}
                  className="mr-3 rounded-circle"
                  style={{ width: "50px" }}
                />
                <a
                  href={void 0}
                  className="btn btn-danger btn-sm"
                  onClick={() => {
                    localStorage.clear()
                    window.location.reload()
                  }}
                >
                  Salir
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="row no-gutters">
          <div className="col-3">
            {qr && <QRCode value={qr} />}
            {chatsLength && (
              <ChatList
                currentChatId={chat && chat.id ? `${chat.id._serialized}_${chat._k_client}` : null}
                handleClick={setActiveChat}
              />
            )}
          </div>
          {chat && (
            <div className={`col-6`}>
              <div
                className="align-items-center bg-secondary flex-row d-flex justify-items-between text-white pl-3 pr-3 shadow-lg position-relative"
                style={{ height: "50px", zIndex: 1 }}
              >
                <div>
                  <b>{chat && chat.id && chat._k_notifyName}</b>
                  <br />
                  <CopyToClipboard
                    text={chat.id.user}
                    className="copy-to-clipboard text-white"
                    onCopy={() => alert("Copiado al portapapeles")}
                  >
                    <small>
                      {chat && chat.id.user}
                      <i className="fa fa-clipboard ml-1"></i>
                    </small>
                  </CopyToClipboard>
                </div>
              </div>
              {chat && (
                <div>
                  <ChatBox chatId={chat.id._serialized} />
                </div>
              )}
              <Composer onSubmit={content => sendText(content)} />
            </div>
          )}
          {chat && (
            <div className="col-3">
              <Cart chat={chat} />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default App
