/** @format */

import React, { useState, useEffect } from "react"
import { LinkItUrl } from "react-linkify-it"

const Chat = ({ args }) => {
  if (args._data.ctwaContext) {
    return (
      <div className={`Message flex-column ${args.fromMe ? "mine" : ""}`}>
        <div className="row">
          <div className="col">{args.body}</div>
        </div>
        <a href={args._data.ctwaContext.sourceUrl} target="_blank" className="row">
          <div className="col-md-3">
            <img src={args._data.ctwaContext.thumbnailUrl} className="w-100" />
          </div>
          <div className="col-md-9">
            <div className="h5">{args._data.ctwaContext.title}</div>
            <div className="">
              <small>
                {args._data.ctwaContext.description
                  ? args._data.ctwaContext.description.substring(0, 20)
                  : ""}
              </small>
            </div>
          </div>
        </a>
      </div>
    )
  } else {
    return (
      <div className={`Message flex-column ${args.fromMe ? "mine" : ""}`}>
        {args.hasQuotedMsg && args._data.quotedMsg.type === "image" && (
          <div className="bg-secondary pl-2 pr-2 pt-1 pb-1 rounded text-light">
            <img
              src={"data:image/png;base64," + args._data.quotedMsg.body}
              style={{ maxWidth: "400px" }}
              className="figure-img img-fluid rounded mb-0"
            />
          </div>
        )}
        {args.hasQuotedMsg && args._data.quotedMsg.type == undefined && (
          <div className="bg-secondary pl-2 pr-2 pt-1 pb-1 rounded text-light">
            <small>{args._data.quotedMsg.body}</small>
          </div>
        )}
        <div className="plain">
          <LinkItUrl>{args.type === "chat" && args.body}</LinkItUrl>
        </div>
      </div>
    )
  }
}

export default Chat
