/** @format */

import React, { useState, useEffect, useRef, useCallback, useMemo } from "react"
import { useForm, useWatch, useFieldArray, useFormContext } from "react-hook-form"
import { useSelector, useDispatch } from "react-redux"
import PropTypes from "prop-types"

import SalesService from "../services/klipsales.service.js"
import { fetchLocations, selectLocationsForChat } from "../features/locations/locations-slice"
import {
  fetchOrders,
  selectOrdersForChat,
  createOrder,
  updateOrder,
  selectOrderById,
  voidOrder,
  confirmOrder,
  selectOrderTotals,
} from "../features/orders/orders-slice"
import Guaranies from "../components/Guaranies"
import OrdersList from "../components/OrdersList"

const Payment = ({ control, deployed, onAdvance, handleSendSummary }) => {
  const [visible, setVisible] = useState(deployed)
  const { setValue, watch, register } = useFormContext()
  const paid = watch("paid")
  const payment_method = watch("payment_method")
  const voidPayment = () => {
    setValue("payment_method", "")
    setValue("payment_ref_number", "")
    setValue("paid", false)
  }
  return (
    <div className="card border-light">
      <div
        className="card-header bg-light text-uppercase font-weight-bold cursor-pointer d-flex justify-content-between"
        onClick={() => setVisible(!visible)}
      >
        <div>
          {!paid && "Pago"}
          {paid && "Pagado"}
          <small className="text-secondary ml-2">
            <i>{payment_method}</i>
          </small>
        </div>
        <div className="">{paid ? "✅" : "⬜️"}</div>
      </div>
      <div className={`card-body ${visible || deployed ? "" : "d-none"}`}>
        {!paid && (
          <div>
            <p className="text-right">
              <a
                href={"#"}
                onClick={() => {
                  handleSendSummary(
                    "*Medios de pago*\n\n*Transferencias*\n```Banco: Itaú\nTitular: Alfredo Re\nCI: 3513995\nCuenta Corriente: 100124606```\n\n*Giros Tigo*\n_Sólo giros, no carga de billetera_ 0985 417 109\n\n*Giros Claro*\n0972 903317\n\n*Tarjetas de crédito*\nComprando en la web de e-commerce www.klipstore.com"
                  )
                }}
              >
                <small>
                  <u>Enviar medios de pago</u>
                </small>
              </a>
            </p>
            <button
              className="btn btn-outline-success btn-block"
              type="button"
              onClick={() => setValue("paid", true)}
            >
              Recibir pago
            </button>
          </div>
        )}
        {paid && (
          <div>
            <div className="form-group">
              <label>Seleccionar forma de pago</label>
              <select className="form-control" {...register("payment_method")}>
                <option>--</option>
                <option value={"cash"}>Efectivo</option>
                <option value={"tigo"}>Tigo</option>
                <option value={"itau"}>Itaú</option>
                <option value={"personal"}>Personal</option>
                <option value={"claro"}>Claro</option>
                <option value={"credit_card"}>Tar. de Crédito</option>
                <option value={"debit_card"}>Tar. de Débito</option>
                <option value={"shopify"}>Online</option>
                <option value={"pagopar_link"}>Link Pagopar</option>
              </select>
            </div>
            <div className="form-group">
              {[
                "tigo",
                "itau",
                "personal",
                "claro",
                "credit_card",
                "debit_card",
                "shopify",
                "pagopar_link",
              ].indexOf(payment_method) >= 0 && (
                <input
                  {...register("payment_ref_number")}
                  placeholder={"Comprobante nro."}
                  className="form-control"
                />
              )}
            </div>
          </div>
        )}
      </div>
      <div
        className={`card-footer text-muted justify-content-between ${
          deployed || visible ? "d-flex" : "d-none"
        }`}
      >
        {paid ? (
          <a href={"#"} className="card-link text-secondary text-decoration-underline" onClick={voidPayment}>
            <small>
              <u>Cancelar pago</u>
            </small>
          </a>
        ) : (
          <div />
        )}
        <button
          type="button"
          className={`btn btn-success btn-sm ${!paid && "disabled"}`}
          onClick={() => {
            setVisible(false)
            onAdvance()
          }}
          disabled={!paid}
        >
          Listo
        </button>
      </div>
    </div>
  )
}

export default Payment
