/** @format */

import { createSlice, createEntityAdapter, nanoid, createAsyncThunk, createSelector } from "@reduxjs/toolkit"

import SalesService from "../../services/klipsales.service.js"
import { selectAllProducts } from "../products/products-slice"

const ordersAdapter = createEntityAdapter({
  selectId: order => order.id,
  sortComparer: (a, b) => a.created_at - b.created_at,
})

const initialState = ordersAdapter.getInitialState({
  status: "idle",
  statuses: {},
  errors: {},
  error: null,
})

const toStrongParams = data => ({ ...data, line_items_attributes: data.line_items })

export const fetchOrders = createAsyncThunk("orders/fetchOrders", async ({ phone, page = 1 }, thunkAPI) => {
  const response = await SalesService.listOrders(phone, page)
  if (response.length) {
    thunkAPI.dispatch(fetchOrders({ phone, page: page + 1 }))
  }
  return response
})

export const fetchLightOrdersList = createAsyncThunk(
  "orders/fetchLightOrdersList",
  async ({ page = 1 } = {}, thunkAPI) => {
    const response = await SalesService.listOrdersById(page)
    if (response.length && page < 2) {
      thunkAPI.dispatch(fetchLightOrdersList({ page: page + 1 }))
    }
    return response
  }
)

export const updateOrder = createAsyncThunk("orders/updateOrder", async ({ id, data }) => {
  const response = await SalesService.updateOrder(id, toStrongParams(data))
  return response
})

export const createOrder = createAsyncThunk("orders/createOrder", async data => {
  const response = await SalesService.createOrder(data)
  return response
})

export const voidOrder = createAsyncThunk("orders/voidOrder", async (params = { restock: true }) => {
  const { id, ...options } = params
  const response = await SalesService.voidOrder(id, options)
  return response
})

export const confirmOrder = createAsyncThunk("orders/confirmOrder", async id => {
  const response = await SalesService.confirmOrder(id)
  return response
})

const ordersSlice = createSlice({
  name: "orders",
  initialState,
  reducers: {
    addOne: {
      prepare: data => {
        return { payload: { ...data, co: "sito" } }
      },
    },
    upsertOneOrder: ordersAdapter.upsertOne,
    // updateOrder: (state, action) => {
    // console.log("updateOrder!")
    // state.entities[action.payload.id] = { ...action.payload, name: "editaditore!" }
    // },
    // receive
    // received: (state, action) => {
    //   state[action.payload.id] = { ...action.payload, line_items_attributes: action.payload.line_items }
    // },
  },
  extraReducers(builder) {
    builder
      .addCase("@@INIT", (state, action) => {
        state.status = "idle"
        state.error = null
        state.statuses = initialState.statuses
        state.errors = initialState.errors
      })
      .addCase(createOrder.fulfilled, (state, action) => {
        ordersAdapter.addOne(state, action.payload)
      })
      .addCase(fetchOrders.pending, (state, action) => {
        state.status = "loading"
      })
      .addCase(fetchOrders.fulfilled, (state, action) => {
        state.status = "succeeded"
        // const normalizedLineItemKey = action.payload.map(o => ({
        //   ...o,
        //   line_items_attributes: o.line_items,
        // }))
        // ordersAdapter.upsertMany(state, normalizedLineItemKey)
        ordersAdapter.upsertMany(state, action.payload)
      })
      .addCase(fetchLightOrdersList.fulfilled, (state, action) => {
        ordersAdapter.upsertMany(state, action.payload)
      })
      .addCase(fetchOrders.rejected, (state, action) => {
        state.status = "failed"
        state.error = action.error.message
      })
      .addCase(updateOrder.pending, (state, action) => {
        state.statuses[action.meta.arg.id] = "loading"
      })
      .addCase(updateOrder.fulfilled, (state, { payload }) => {
        state.statuses[payload.id] = "succeeded"
        const { id, ...changes } = payload
        ordersAdapter.updateOne(state, { id, changes })
      })
      .addCase(updateOrder.rejected, (state, action) => {
        console.log("action!")
        console.log(action)
        state.statuses[action.meta.arg.id] = "idle"
      })
      .addCase(confirmOrder.pending, (state, action) => {
        state.statuses[action.meta.arg.id] = "loading"
      })
      .addCase(confirmOrder.fulfilled, (state, { payload }) => {
        state.statuses[payload.id] = "succeeded"
        const { id, ...changes } = payload
        ordersAdapter.updateOne(state, { id, changes })
      })
      .addCase(voidOrder.pending, (state, action) => {
        state.statuses[action.meta.arg.id] = "loading"
      })
      .addCase(voidOrder.fulfilled, (state, { payload }) => {
        state.statuses[payload.id] = "succeeded"
        const { id, ...changes } = payload
        ordersAdapter.updateOne(state, { id, changes })
      })
      .addCase(voidOrder.rejected, (state, action) => {
        state.statuses[action.meta.arg.id] = "idle"
        state.status = "failed"
        state.error = action.error.message
      })
  },
})

export const {
  selectAll: selectAllOrders,
  selectById: selectOrderById,
  // selectIds: selectMessageIds,
  // Pass in a selector that returns the posts slice of state,
} = ordersAdapter.getSelectors(state => state.orders)

export const selectOrdersForChat = createSelector(
  [selectAllOrders, (state, phone) => phone],
  (items, phone) => {
    const filtered = items.filter(order => {
      return order.phone === phone
    })
    return filtered
  }
)

export const selectLatestOrderForChat = createSelector(selectOrdersForChat, items => {
  return items[items.length - 1]
})

export const selectOrderTotals = createSelector(selectOrderById, selectAllProducts, (order, products) => {
  if (!order) {
    return {
      products: 0,
      shipping: 0,
      total: 0,
    }
  }
  const prod = order.line_items
    ? order.line_items
        .filter(x => !x._destroy)
        .reduce((partialSum, item) => {
          const product = products.find(p => p.id == item.product_id)
          let val = product.price * item.quantity
          if (item.discount && item.discount_type === "amount") {
            val = val - item.discount
          }
          if (item.discount && item.discount_type === "percentage") {
            val = val - (parseFloat(item.discount) * product.price) / 100
          }
          return partialSum + val
        }, 0)
    : 0
  return {
    products: prod,
    shipping: ["remote", "local"].indexOf(order.shipping_type) >= 0 ? order.shipping_price : 0,
    total: prod + order.shipping_price,
  }
})
export const { upsertOneOrder } = ordersSlice.actions
export default ordersSlice.reducer
