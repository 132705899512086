/** @format */
import axios from "axios"
import http from "../http.whatsapp.js"

// const alwaysAppendQs = "?installation_id=2c2528d0-4008-49be-bbee-e005eff48cfb"
// const alwaysAppendQs = "?installation_id=135b4a9b-76d3-4b0b-adf2-9a6f6e765e58"
const _db_http = axios.create({
  // baseURL: "http://localhost:5001/",
  baseURL: "https://wa.klipstore.com:5001/",
  headers: {
    "Content-type": "application/json",
  },
})
class WhatsAppService {
  // getAll() {
  //   try {
  //     return http.get("/articles/list" + alwaysAppendQs).then(res => res.data["response"])
  //   } catch (e) {
  //     console.log(e)
  //   }
  // }

  // update(id, data) {
  //   return http.patch(`/articles/${id}/update` + alwaysAppendQs, data).then(res => res.data["response"])
  // }

  listChats(page = 1) {
    return _db_http.get(`/chats/${page}`).then(res => res.data)
  }

  getOneChat(chatId) {
    return _db_http.get(`/chats/${chatId}/show`).then(res => res.data)
  }

  listMessages(chatId, page = 1) {
    // return []
    // return http.get(`/chats/${chatId}/messages`).then(res => res.data)
    return _db_http.get(`/chats/${chatId}/messages/${page}`).then(res => res.data)
  }

  listLocations(chatId, page = 1) {
    return _db_http.get(`/chats/${chatId}/locations/${page}`).then(res => res.data)
  }

  sendMessage(from, to, content) {
    return http.post(`${from}/chats/${to}/sendtext`, { content: content }).then(res => res.data)
  }

  sendImage(from, to, content) {
    return http.post(`${from}/chats/${to}/sendImage`, { content: content }).then(res => res.data)
  }

  // show(id) {
  //   return http.get(`/feed_blueprints/${id}` + alwaysAppendQs).then(res => res.data["response"])
  // }
}

export default new WhatsAppService()
