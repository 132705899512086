/** @format */

import React, { useState, useEffect, useRef, useCallback, useMemo } from "react"
import { useForm, useWatch, useFieldArray, useFormContext } from "react-hook-form"
import { useSelector, useDispatch } from "react-redux"
import PropTypes from "prop-types"

import SalesService from "../services/klipsales.service.js"
import { fetchLocations, selectLocationsForChat } from "../features/locations/locations-slice"
import {
  fetchOrders,
  selectOrdersForChat,
  createOrder,
  updateOrder,
  selectOrderById,
  voidOrder,
  confirmOrder,
  selectOrderTotals,
} from "../features/orders/orders-slice"
import { selectAllProducts } from "../features/products/products-slice"
import Guaranies from "../components/Guaranies"
import OrdersList from "../components/OrdersList"

const CartSection = ({ control, products, deployed, onAdvance }) => {
  const { watch, register } = useFormContext() // retrieve all hook methods
  const [visible, setVisible] = useState(deployed)
  const [visibleDiscountFields, setVisibleDiscountFields] = useState([])
  const [prodSearch, setProdSearch] = useState("")
  const { update, append } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: "line_items", // unique name for your Field Array
  })
  const formState = useWatch({
    control,
  })
  const line_items = watch("line_items")
  const shipping_price = watch("shipping_price")
  const handleDiscountWWW = index => {
    setVisibleDiscountFields([...visibleDiscountFields, index])
  }
  return (
    <div className="card border-light">
      <div
        className="card-header bg-light text-uppercase font-weight-bold cursor-pointer d-flex justify-content-between"
        onClick={() => setVisible(!visible)}
      >
        {line_items.length == 0 && "Carrito"}
        {line_items.length > 0 && (
          <div>
            {line_items.length} producto{line_items.length > 1 ? "s" : ""}
          </div>
        )}
        <div className="">{line_items.filter(i => !i._destroy).length > 0 ? "✅" : "⬜️"}</div>
      </div>
      <div className={`card-body ${deployed || visible ? "" : "d-none"}`}>
        <div className="border-primary">
          <input
            className="form-control form-control-sm border-warning"
            placeholder="Buscar producto a agregar"
            value={prodSearch}
            autoFocus
            onChange={e => setProdSearch(e.target.value)}
          />
          <div className="list-group border-primary overflow-auto" style={{ maxHeight: "20vh" }}>
            {products
              .filter(p => prodSearch && p.name.toLowerCase().includes(prodSearch.toLowerCase()))
              .map(p => (
                <div
                  key={`p_search_${p.id}`}
                  className="list-group-item list-group-item-action"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setProdSearch("")
                    append({
                      quantity: 1,
                      product_id: p.id,
                    })
                  }}
                >
                  <div className="d-flex">
                    <img src={p.image_url} className="mr-3" width="50px" />
                    <small>
                      <b>{p.name}</b>
                    </small>
                  </div>
                </div>
              ))}
          </div>
        </div>
        {line_items.length > 0 && (
          <div>
            <hr />
            <div className="list-unstyled">
              <div className="list-group list-group-flush">
                {line_items.map((item, index) => {
                  const product = products.find(p => p.id == item.product_id)
                  if (!product) {
                    return (
                      <div
                        className={`list-group-item p-0 border-bottom ${item._destroy && "d-none"}`}
                        key={`soft_lineitem_${index}`}
                      >
                        Cargando productos...
                      </div>
                    )
                  }
                  return (
                    <div
                      className={`list-group-item p-0 border-bottom mb-2 ${item._destroy && "d-none"}`}
                      key={`soft_lineitem_${index}`}
                    >
                      <div className="d-flex">
                        <div className="mr-3">
                          <img src={product ? product.image_url : ""} style={{ width: "35px" }} />
                        </div>
                        <div className="flex-grow-1">
                          <div className="">
                            <small className="font-weight-bold text-nowrap">
                              {item._destroy && <p>[DELETED]</p>}
                              {product ? product.name : ""}
                            </small>
                          </div>
                          <div className="d-flex justify-content-between">
                            <small className="text-secondary">
                              <a
                                href="#"
                                className={`badge badge-${
                                  item.quantity == 1 ? "secondary" : "primary"
                                } rounded`}
                                disabled={item.quantity == 1}
                                onClick={() =>
                                  item.quantity > 1 && update(index, { ...item, quantity: item.quantity - 1 })
                                }
                              >
                                <b>-</b>
                              </a>
                              <span className="ml-2 mr-2">
                                <b>{item.quantity}</b>
                              </span>
                              <a
                                href="#"
                                className="badge badge-primary rounded"
                                onClick={() => update(index, { ...item, quantity: item.quantity + 1 })}
                              >
                                <b>+</b>
                              </a>{" "}
                              x <Guaranies>{product.price}</Guaranies>
                            </small>
                            <a
                              href={"#"}
                              className="text-danger"
                              onClick={() => update(index, { ...item, _destroy: true })}
                            >
                              <small>Eliminar</small>
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="d-flex">
                        {visibleDiscountFields.indexOf(index) < 0 && (
                          <a href="#" onClick={() => handleDiscountWWW(index)}>
                            <small>Descuento?</small>
                          </a>
                        )}
                      </div>
                      <div className={`row ${visibleDiscountFields.indexOf(index) < 0 && "d-none"}`}>
                        <div className="col">
                          <div className="form-row">
                            <label className="col-sm-4 col-form-label-sm">Descuento</label>
                            <div className="col-sm-8">
                              <div className="input-group">
                                <input
                                  type="number"
                                  className="form-control form-control-sm"
                                  value={item.discount}
                                  onChange={e =>
                                    update(index, { ...item, discount: parseInt(e.target.value) })
                                  }
                                />
                                <div className="input-group-append">
                                  <button
                                    type="button"
                                    className={`btn btn-sm btn-${
                                      item.discount_type === "amount" ? "primary" : "secondary"
                                    }`}
                                    onClick={() => update(index, { ...item, discount_type: "amount" })}
                                  >
                                    <b>Gs.</b>
                                  </button>
                                  <button
                                    type="button"
                                    className={`btn btn-sm btn-${
                                      item.discount_type === "percentage" ? "primary" : "secondary"
                                    }`}
                                    onClick={() => update(index, { ...item, discount_type: "percentage" })}
                                  >
                                    <b>%</b>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="form-row">
                            <label className="col-sm-4 col-form-label-sm">Motivo</label>
                            <div className="col-sm-8">
                              <input
                                type="text"
                                className="form-control form-control-sm"
                                value={item.discount_reason}
                                onChange={e => update(index, { ...item, discount_reason: e.target.value })}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
        )}
        {line_items.length > 0 && (
          <div className="form-group mt-2">
            <label>
              <small>Pedidos especiales?</small>
            </label>
            <textarea
              {...register("customer_notes")}
              placeholder="Grabados, envuelto p/ regalo, etc."
              className="form-control form-control-sm"
            ></textarea>
          </div>
        )}
      </div>
      <div
        className={`card-footer text-muted text-right ${
          deployed || visible ? "d-flex flex-row-reverse" : "d-none"
        }`}
      >
        <button
          type="button"
          className={`btn btn-success btn-sm ${
            line_items.filter(i => !i._destroy).length == 0 && "disabled"
          }`}
          onClick={() => {
            setVisible(!visible)
            onAdvance()
          }}
          disabled={line_items.filter(i => !i._destroy).length == 0}
        >
          Listo
        </button>
      </div>
    </div>
  )
}

export default CartSection
