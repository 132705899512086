/** @format */

import { createSlice, createEntityAdapter, nanoid, createAsyncThunk, createSelector } from "@reduxjs/toolkit"
import {
  fetchMessages,
  selectAllMessages,
  selectMessagesForChat,
  messageAdded,
} from "../messages/messages-slice"
import { selectAllProducts } from "../products/products-slice"

import SalesService from "../../services/klipsales.service.js"
import WhatsAppService from "../../services/whatsapp.service.js"

const citiesAdapter = createEntityAdapter({
  selectId: city => city.id,
  sortComparer: (a, b) => b.send_count - a.send_count,
})

const initialState = citiesAdapter.getInitialState({
  status: "idle",
  error: null,
})

const citiesSlice = createSlice({
  name: "cities",
  initialState,
  reducers: {
    hydrate: (state, action) => {
      // do not do state = action.payload it will not update the store
      return action.payload
    },
    // receive
    // received: (state, action) => {
    //   state[action.payload.id] = action.payload
    // },
  },
  extraReducers(builder) {
    builder
      .addCase("@@INIT", (state, action) => {
        state.status = "idle"
      })
      .addCase(fetchCities.pending, (state, action) => {
        state.status = "loading"
      })
      .addCase(fetchCities.fulfilled, (state, action) => {
        state.status = "succeeded"
        citiesAdapter.upsertMany(state, action.payload)
      })
      .addCase(fetchCities.rejected, (state, action) => {
        state.status = "failed"
        state.error = action.error.message
      })
  },
})

export const fetchCities = createAsyncThunk("cities/fetchCities", async ({ page = 1 } = {}, thunkAPI) => {
  const response = await SalesService.listCities("", page)
  if (response.length && page < 3) {
    thunkAPI.dispatch(fetchCities({ page: page + 1 }))
  }
  return response
})

export const {
  selectAll: selectAllCities,
  selectById,
  // selectIds: selectMessageIds,
  // Pass in a selector that returns the posts slice of state,
} = citiesAdapter.getSelectors(state => state.cities)

// export const selectOrdersForChat = createSelector(
//   [selectAllCities, (state, phone) => phone],
//   (items, phone) => {
//     const filtered = items.filter(order => {
//       return order.phone === phone
//     })
//     return filtered
//   }
// )
export const { received } = citiesSlice.actions
export default citiesSlice.reducer

export const selectNonProductCities = createSelector(
  selectAllCities,
  selectAllProducts,
  (cities, products) => {
    const ids = products.map(p => p.city_id).filter(Boolean)
    return cities.filter(r => ids.indexOf(r.id) < 0)
  }
)

export const selectAllCitiesWithReplies = createSelector(selectAllCities, cities =>
  cities.filter(c => c.reply_id != null)
)
