/** @format */

import React, { useState, useEffect, useRef, useCallback, useMemo } from "react"
import { useForm, useWatch, useFieldArray, useFormContext } from "react-hook-form"
import { useSelector, useDispatch } from "react-redux"
import PropTypes from "prop-types"

import WhatsAppService from "../services/whatsapp.service.js"
import SalesService from "../services/klipsales.service.js"
import { fetchLocations, selectLocationsForChat } from "../features/locations/locations-slice"
import {
  fetchOrders,
  selectOrdersForChat,
  createOrder,
  updateOrder,
  selectOrderById,
  voidOrder,
  confirmOrder,
  selectOrderTotals,
} from "../features/orders/orders-slice"
import { selectAllProducts } from "../features/products/products-slice"
import Guaranies, { formatter } from "../components/Guaranies"
import OrdersList from "../components/OrdersList"

const SummarySection = ({ productsTotal, handleSendSummary }) => {
  const { watch } = useFormContext()
  const shipping_price = parseInt(watch("shipping_price"))

  return (
    <div className="card bg-dark">
      <div className="card-header bg-dark text-white text-uppercase font-weight-bold">RESUMEN</div>
      <div className="card-body">
        <table className="table table-sm table-borderless text-white mb-0">
          <tbody>
            <tr>
              <td>
                <b>Productos: </b>
              </td>
              <td className="text-right">
                <Guaranies>{productsTotal}</Guaranies>
              </td>
            </tr>
            <tr>
              <td>
                <b>Envío: </b>
              </td>
              <td className="text-right">
                <Guaranies>{shipping_price}</Guaranies>
              </td>
            </tr>
            <tr>
              <td>
                <b>TOTAL: </b>
              </td>
              <td className="text-right">
                <Guaranies>{productsTotal + shipping_price}</Guaranies>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="card-footer text-muted">
        <button
          className="btn btn-block btn-sm btn-light"
          type="button"
          onClick={() => {
            handleSendSummary(
              `Productos  ${formatter.format(productsTotal)}\nEnvío  ${formatter.format(
                shipping_price
              )}\n*Total  ${formatter.format(productsTotal + shipping_price)}*`
            )
          }}
        >
          Enviar
        </button>
      </div>
    </div>
  )
}

export default SummarySection
