/** @format */

import React, { useState, useEffect, useRef, useCallback, useMemo } from "react"
import { useForm, useWatch, useFieldArray, useFormContext, useFormState } from "react-hook-form"
import { useSelector, useDispatch } from "react-redux"
import PropTypes from "prop-types"

import WhatsAppService from "../services/whatsapp.service.js"
import SalesService from "../services/klipsales.service.js"
import { fetchLocations, selectLocationsForChat } from "../features/locations/locations-slice"
import {
  fetchOrders,
  selectOrdersForChat,
  createOrder,
  updateOrder,
  selectOrderById,
  voidOrder,
  confirmOrder,
  selectOrderTotals,
} from "../features/orders/orders-slice"
import { selectAllProducts } from "../features/products/products-slice"
import Guaranies, { formatter } from "../components/Guaranies"
import OrdersList from "../components/OrdersList"
import StatusToEmoji from "../components/StatusToEmoji"

const OrderHeader = ({ order, control, busy, handleBack }) => {
  const { watch, register } = useFormContext()
  const { isDirty } = useFormState({
    control,
  })
  const status = watch("status")
  return (
    <nav
      className="align-items-center bg-secondary flex-row d-flex justify-items-between"
      style={{ height: "50px" }}
    >
      <div className="col">
        <button type="button" className="btn btn-sm btn-outline-light" onClick={handleBack}>
          Volver
        </button>
      </div>
      <div className="col text-center">
        <div className="">
          <small className="font-weight-bold">
            <StatusToEmoji status={status} /> #{order.number}
          </small>
        </div>
      </div>
      <div className="col text-right">
        <button
          type="submit"
          className="btn btn-sm btn-success"
          // disabled={!isDirty || busy || !order.created_at}
        >
          {busy ? "Guardando" : "Guardar"}
        </button>
      </div>
    </nav>
  )
}

export default OrderHeader
