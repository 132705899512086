/** @format */

import {
  createSlice,
  createSelector,
  createEntityAdapter,
  nanoid,
  createAsyncThunk,
  createAction,
} from "@reduxjs/toolkit"

import WhatsAppService from "../../services/whatsapp.service.js"
import {
  createOrder,
  updateOrder,
  confirmOrder,
  voidOrder,
  fetchLightOrdersList,
  upsertOneOrder,
} from "../orders/orders-slice"
import messageToSnippet from "../../lib/messageToSnippet"

export const setFilterValue = createAction("chats/filterValue/set")
export const setAccountFilterValue = createAction("chats/accountFilterValue/set")
export const setTextSearch = createAction("chats/textSearch/set")

const chatsAdapter = createEntityAdapter({
  selectId: chat => `${chat.id._serialized}_${chat._k_client}`,
  sortComparer: (a, b) => b.timestamp - a.timestamp,
})

const initialState = chatsAdapter.getInitialState({
  status: "idle",
  error: null,
  textSearch: "",
  filterValue: "",
  filterValue: "location_without_order",
  // accountFilterValue: "",
  accountFilterValue: "595993468653@c.us",
  // accountFilterValue: "595992254122@c.us",
})

export const fetchChats = createAsyncThunk("chats/fetchChats", async ({ page = 1 } = {}, thunkAPI) => {
  const response = await WhatsAppService.listChats(page)
  if (response.length && page < 20) {
    thunkAPI.dispatch(fetchChats({ page: page + 1 }))
  } else {
    thunkAPI.dispatch(fetchLightOrdersList())
  }
  return response
})

export const upsertBasedOnNewMsg = createAsyncThunk(
  "chats/upsertBasedOnNewMsg",
  async ({ message }, thunkAPI) => {
    const state = thunkAPI.getState()
    const elchatid = state.chats.ids.find(c => c.includes(message.id.remote))
    if (elchatid) {
      console.log(`chat ${elchatid} exists, will update`)
      let receivedLocationName = ""
      let tagChatWithLocation = ""
      if (message.type === "location") {
        tagChatWithLocation = true
        receivedLocationName = "Ubicación"
      }
      const chatChanges = {
        _k_lastMsgSnippet: messageToSnippet({ message, receivedLocationName }),
        _k_lastMsgAck: message.ack,
        timestamp: message.timestamp,
      }
      if (tagChatWithLocation) {
        chatChanges["_k_hasLocation"] = true
      }
      thunkAPI.dispatch(
        chatUpdated({
          id: `${message.id.remote}_${message._k_client}`,
          changes: chatChanges,
        })
      )
    } else {
      console.log(`chat ${elchatid} does not exist, will fetch`)
      thunkAPI.dispatch(fetchOneChat({ chatId: message.id.remote }))
    }
  }
)

export const fetchOneChat = createAsyncThunk("chats/fetchOne", async ({ chatId } = {}, thunkAPI) => {
  const response = await WhatsAppService.getOneChat(chatId)
  if (response) {
    thunkAPI.dispatch(upsertOneChat(response))
  }
  return response
})

const updateRelatedOrderStatus = (state, action) => {
  const elchatid = state.ids.find(c => c.includes(action.payload.phone))
  if (elchatid && action.payload.phone.length > 0) {
    chatsAdapter.updateOne(state, {
      id: elchatid,
      changes: {
        latest_order_status: action.payload.status,
        latest_order_id: action.payload.id,
        latest_order_number: action.payload.number,
      },
    })
  }
}

const chatsSlice = createSlice({
  name: "chats",
  initialState,
  reducers: {
    // receive
    // received: (state, action) => {
    //   state.push(action.payload)
    // },
    // chatsReceived(state, action) {
    //   // Or, call them as "mutating" helpers in a case reducer
    //   chatsAdapter.setAll(state, action.payload.chats)
    // },
    // chatAdded: chatsAdapter.addOne,
    // chatRemoved: chatsAdapter.removeOne,
    upsertOneChat: chatsAdapter.upsertOne,
    chatUpdated: chatsAdapter.updateOne,
    // chatUpdated(state, action) {
    //   const { timestamp } = action.payload
    //   const id = action.payload.id._serialized
    //   const existingChat = state.entities[id]
    //   if (existingChat) {
    //     existingChat.timestamp = timestamp
    //   }
    // },
  },
  extraReducers(builder) {
    builder
      .addCase("@@INIT", (state, action) => {
        state.status = initialState.status
        state.filterValue = initialState.filterValue
        state.textSearch = initialState.textSearch
        state.accountFilterValue = initialState.accountFilterValue
      })
      .addCase(setFilterValue, (state, action) => {
        state.filterValue = action.payload
      })
      .addCase(setAccountFilterValue, (state, action) => {
        state.accountFilterValue = action.payload
      })
      .addCase(setTextSearch, (state, action) => {
        state.textSearch = action.payload
      })
      .addCase(fetchChats.pending, (state, action) => {
        state.status = "loading"
      })
      .addCase(fetchChats.fulfilled, (state, action) => {
        state.status = "succeeded"
        chatsAdapter.upsertMany(state, action.payload)
      })
      .addCase(fetchChats.rejected, (state, action) => {
        state.status = "failed"
        state.error = action.error.message
      })
      .addCase(fetchLightOrdersList.fulfilled, (state, action) => {
        const unique = [...new Map(action.payload.map(item => [item["phone"], item])).values()]
        const updates = unique
          .map(o => {
            //
            // El chat.id de redux ya no es solo el telefono, ahora incluye el client id, asi que
            // se hace un match parcial del order.phone dentro del chat.id
            //
            const elchatid = state.ids.find(c => c.includes(o.phone))
            if (elchatid && o.phone.length > 0) {
              // console.log(`#${o.number} phone: ${o.phone.length}, chatid: ${elchatid}`)
              // if (elchatid.includes("983366")) {
              // console.log("este!", elchatid, o)
              // }
              return {
                // id: `${o.phone}@c.us`,
                id: elchatid,
                changes: {
                  latest_order_status: o.status,
                  latest_order_id: o.id,
                  latest_order_number: o.number,
                },
              }
            } else {
              return null
            }
          })
          .filter(Boolean)
        chatsAdapter.updateMany(state, updates)
      })
      .addCase(createOrder.fulfilled, updateRelatedOrderStatus)
      .addCase(updateOrder.fulfilled, updateRelatedOrderStatus)
      .addCase(confirmOrder.fulfilled, updateRelatedOrderStatus)
      .addCase(voidOrder.fulfilled, updateRelatedOrderStatus)
      .addCase(upsertOneOrder, updateRelatedOrderStatus)
    // Use the `addOne` reducer for the fulfilled case
    // .addCase(addNewChat.fulfilled, chatsAdapter.addOne)
  },
})

// export const { postAdded, postUpdated, reactionAdded } = postsSlice.actions
export const { chatAdded, chatUpdated, upsertOneChat } = chatsSlice.actions

// Export the customized selectors for this adapter using `getSelectors`
export const {
  selectAll: selectAllChats,
  selectById: selectChatById,
  selectIds: selectChatIds,
  // Pass in a selector that returns the posts slice of state
} = chatsAdapter.getSelectors(state => state.chats)

export const filterByStatus = createSelector(
  // selectAllChats,
  state => state.chats.filterValue,
  (chats, filterValue) => {
    // console.log("filterByStatus")
    // console.log(filterValue)
    // console.log(chats)
    return chats
    // switch (filterValue) {
    //   case "draft":
    //   case "confirmed":
    //   case "processing":
    //   case "ready":
    //   case "shipped":
    //   case "delivered":
    //   case "cancelled":
    //     return chats.filter(c => c.latest_order_status === filterValue)
    //   case "location_without_order":
    //     // return chats.filter(c => c._k_hasLocation && !c.latest_order_id)
    //     return chats.filter(c => c._k_hasLocation && c.latest_order_status != "confirmed")
    //   case "unread":
    //     // return chats.filter(c => c._k_hasLocation && !c.latest_order_id)
    //     return chats.filter(c => c.unreadCount > 0)
    //   default:
    //     return chats
    // }
  }
)
export const filterByAccount = createSelector(
  selectAllChats,
  state => state.chats.accountFilterValue,
  (chats, accountFilterValue) => {
    switch (accountFilterValue) {
      case "595993468653@c.us":
      case "595992254122@c.us":
        return chats.filter(c => c._k_client === accountFilterValue)
      default:
        return chats
    }
  }
)

const ignoreAsClients = ["595982585668", "595992261680"]

export const selectFilteredChats = createSelector(
  selectAllChats,
  state => state.chats.accountFilterValue,
  state => state.chats.filterValue,
  state =>
    state.chats.textSearch
      ? state.chats.textSearch
          .toLowerCase()
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
      : "",
  (chats, accountFilterValue, filterValue, normalizedSearch) => {
    // console.log(chats.length, accountFilterValue, filterValue, normalizedSearch)
    return chats
      .filter(c => (accountFilterValue ? c._k_client === accountFilterValue : c))
      .filter(c => {
        switch (filterValue) {
          case "draft":
          case "confirmed":
          case "processing":
          case "ready":
          case "shipped":
          case "delivered":
          case "cancelled":
            return c.latest_order_status === filterValue
          case "location_without_order":
            return c._k_hasLocation && !c.latest_order_id && ignoreAsClients.indexOf(c.id.user) < 0
          case "unread":
            return c.unreadCount > 0
          default:
            return c
        }
      })
      .filter(c => {
        if (!normalizedSearch || normalizedSearch.length == 0) return c
        // console.log("c.latest_order_number")
        // console.log(c.latest_order_number)
        return (
          (c.latest_order_number && c.latest_order_number.toString().includes(normalizedSearch)) ||
          c.id.user.includes(normalizedSearch.replace(/^0/, "")) ||
          c.name
            .toLowerCase()
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
            .includes(normalizedSearch) ||
          (c._k_notifyName &&
            c._k_notifyName
              .toLowerCase()
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, "")
              .includes(normalizedSearch))
        )
      })
  }
)

export default chatsSlice.reducer
