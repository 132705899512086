/** @format */

import React, { useState, useEffect, useRef, useCallback, useMemo } from "react"
import { useForm, useWatch, useFieldArray, useFormContext, useFormState } from "react-hook-form"
import { useSelector, useDispatch } from "react-redux"
import PropTypes from "prop-types"

import WhatsAppService from "../services/whatsapp.service.js"
import SalesService from "../services/klipsales.service.js"
import { fetchLocations, selectLocationsForChat } from "../features/locations/locations-slice"
import {
  fetchOrders,
  selectOrdersForChat,
  createOrder,
  updateOrder,
  selectOrderById,
  voidOrder,
  confirmOrder,
  selectOrderTotals,
} from "../features/orders/orders-slice"
import { selectAllProducts } from "../features/products/products-slice"
import Guaranies, { formatter } from "../components/Guaranies"
import OrdersList from "../components/OrdersList"

const OrderFooter = ({
  order,
  control,
  status,
  statuses,
  busy,
  handleBack,
  deployed,
  handleDeploy,
  productsTotal,
  handleOrderConfirmation,
  handleVoidOrder,
  handleSendSummary,
}) => {
  const { watch, register } = useFormContext()
  const { isDirty } = useFormState({
    control,
  })
  const shipping_price = parseInt(watch("shipping_price"))
  return (
    <nav
      className="bg-dark d-flex flex-column position-relative p-2 shadow-lg"
      style={{ height: deployed ? "150px" : "50px" }}
    >
      {!deployed && (
        <table className="table table-sm table-borderless text-white mb-0">
          <tbody>
            <tr>
              <td>
                <b>TOTAL: </b>
              </td>
              <td className="text-right">
                <Guaranies>{productsTotal + shipping_price}</Guaranies>
              </td>
            </tr>
          </tbody>
        </table>
      )}
      {deployed && (
        <div>
          <table className="table table-sm table-borderless text-white mb-0">
            <tbody>
              <tr className="text-light">
                <td>
                  <b>Productos: </b>
                </td>
                <td className="text-right">
                  <Guaranies>{productsTotal}</Guaranies>
                </td>
              </tr>
              <tr className="text-light">
                <td>
                  <b>Envío: </b>
                </td>
                <td className="text-right">
                  <Guaranies>{shipping_price}</Guaranies>
                </td>
              </tr>
              <tr>
                <td>
                  <b>TOTAL: </b>
                </td>
                <td className="text-right">
                  <b>
                    <Guaranies>{productsTotal + shipping_price}</Guaranies>
                  </b>
                </td>
              </tr>
            </tbody>
          </table>
          <div className="text-right">
            <a
              href={"#"}
              className="text-light"
              onClick={() => {
                handleSendSummary(
                  `Productos  ${formatter.format(productsTotal)}\nEnvío  ${formatter.format(
                    shipping_price
                  )}\n*Total  ${formatter.format(productsTotal + shipping_price)}*`
                )
              }}
            >
              <small>
                <u>Enviar resumen</u>
              </small>
            </a>
          </div>
        </div>
      )}
      <a
        href={"#"}
        className="bg-dark d-flex p-1 pl-2 pr-2 text-white"
        style={{
          position: "absolute",
          top: "-32px",
          right: "0px",
        }}
        onClick={() => handleDeploy()}
      >
        {deployed ? "⬇︎" : "⬆︎"}
      </a>
    </nav>
  )
}

export default OrderFooter
