/** @format */

import { createSlice, createEntityAdapter, nanoid, createAsyncThunk, createSelector } from "@reduxjs/toolkit"
import {
  fetchMessages,
  selectAllMessages,
  selectMessagesForChat,
  messageAdded,
} from "../messages/messages-slice"
import { selectAllProducts } from "../products/products-slice"
import { selectAllCities } from "../cities/cities-slice"

import SalesService from "../../services/klipsales.service.js"
import WhatsAppService from "../../services/whatsapp.service.js"

const repliesAdapter = createEntityAdapter({
  selectId: reply => reply.id,
  sortComparer: (a, b) => b.send_count - a.send_count,
})

const initialState = repliesAdapter.getInitialState({
  status: "idle",
  error: null,
})

const repliesSlice = createSlice({
  name: "replies",
  initialState,
  reducers: {
    hydrate: (state, action) => {
      // do not do state = action.payload it will not update the store
      return action.payload
    },
    // receive
    // received: (state, action) => {
    //   state[action.payload.id] = action.payload
    // },
  },
  extraReducers(builder) {
    builder
      .addCase("@@INIT", (state, action) => {
        state.status = "idle"
      })
      .addCase(fetchReplies.pending, (state, action) => {
        state.status = "loading"
      })
      .addCase(fetchReplies.fulfilled, (state, action) => {
        state.status = "succeeded"
        repliesAdapter.upsertMany(state, action.payload)
      })
      .addCase(fetchReplies.rejected, (state, action) => {
        state.status = "failed"
        state.error = action.error.message
      })
  },
})

export const fetchReplies = createAsyncThunk("replies/fetchReplies", async ({ page = 1 } = {}, thunkAPI) => {
  console.log(`fetchReplies ${page}`)
  const response = await SalesService.listReplies(page)
  // if (response.length && page < 3) {
  // thunkAPI.dispatch(fetchReplies({ page: page + 1 }))
  // }
  return response
})

export const sendReply = createAsyncThunk(
  "replies/sendReply",
  async ({ from, chatId, replyId }, thunkAPI) => {
    const reply = selectById(thunkAPI.getState(), replyId)
    SalesService.bumpReplySendCount(replyId)
    return reply.messages.reduce((p, message, currentIndex, array) => {
      return p.then(() => {
        switch (array[currentIndex].type) {
          case "text":
            return WhatsAppService.sendMessage(from, chatId, array[currentIndex].payload).then(args => {
              thunkAPI.dispatch(messageAdded(args))
            })
            break
          case "media":
            return WhatsAppService.sendImage(from, chatId, array[currentIndex].payload).then(args => {
              thunkAPI.dispatch(messageAdded(args))
              return
            })
            break
          default:
            return Promise.resolve()
        }
      })
    }, Promise.resolve())
  }
)

export const {
  selectAll: selectAllReplies,
  selectById,
  // selectIds: selectMessageIds,
  // Pass in a selector that returns the posts slice of state,
} = repliesAdapter.getSelectors(state => state.replies)

// export const selectOrdersForChat = createSelector(
//   [selectAllReplies, (state, phone) => phone],
//   (items, phone) => {
//     const filtered = items.filter(order => {
//       return order.phone === phone
//     })
//     return filtered
//   }
// )
export const { received } = repliesSlice.actions
export default repliesSlice.reducer

export const selectNonProductReplies = createSelector(
  selectAllReplies,
  selectAllProducts,
  selectAllCities,
  (replies, products, cities) => {
    const p_ids = products.map(p => p.reply_id).filter(Boolean)
    const c_ids = cities.map(p => p.reply_id).filter(Boolean)
    const ids = [...p_ids, ...c_ids]
    return replies.filter(r => ids.indexOf(r.id) < 0)
  }
)
