/** @format */

import React, { useState, useEffect, useRef, useCallback, useMemo } from "react"
import { useForm, useWatch, useFieldArray, FormProvider } from "react-hook-form"
import { useSelector, useDispatch } from "react-redux"
import PropTypes from "prop-types"
import useWebSocket, { ReadyState } from "react-use-websocket"

import WhatsAppService from "./services/whatsapp.service.js"
import SalesService from "./services/klipsales.service.js"
import { fetchLocations, selectLocationsForChat } from "./features/locations/locations-slice"
import {
  fetchOrders,
  selectOrdersForChat,
  createOrder,
  updateOrder,
  selectOrderById,
  voidOrder,
  confirmOrder,
  selectOrderTotals,
} from "./features/orders/orders-slice"
import {
  fetchMessages,
  selectAllMessages,
  selectMessagesForChat,
  messageAdded,
} from "./features/messages/messages-slice"
import { selectAllProducts } from "./features/products/products-slice"
import Guaranies from "./components/Guaranies"
import OrdersList from "./components/OrdersList"
import CartSection from "./components/CartSection"
import ShippingSection from "./components/ShippingSection"
import PaymentSection from "./components/PaymentSection"
import CustomerSection from "./components/CustomerSection"
import InvoiceSection from "./components/InvoiceSection"
import OrderHeader from "./components/OrderHeader"
import OrderFooter from "./components/OrderFooter"
import SummarySection from "./components/SummarySection"
import QuickReplies from "./components/QuickReplies"

const socketUrl = "wss://wa.klipstore.com:7071"

const Preview = ({ control }) => {
  const formState = useWatch({
    control,
  })
  return (
    <div className="bg-light p-1">
      <pre>
        <code>{JSON.stringify(formState, null, 2)}</code>
      </pre>
    </div>
  )
}

const Cart = ({ chat }) => {
  const dispatch = useDispatch()
  const orders = useSelector(state => selectOrdersForChat(state, chat.id.user))
  const ordersStatus = useSelector(state => state.orders.status)
  const statuses = useSelector(state => state.orders.statuses)
  const locations = useSelector(state => selectLocationsForChat(state, chat.id._serialized))
  const products = useSelector(state => selectAllProducts(state))
  const [activeOrder, setActiveOrder] = useState(null)
  const [currentOrder, setCurrentOrder] = useState(null)
  const [canCreateOrder, setCanCreateOrder] = useState(false)
  const leactiv = useSelector(state => selectOrderById(state, activeOrder))
  const [productsTotal, setProductsTotal] = useState(0)
  const [deployedSection, setDeployedSection] = useState("cart")
  const [footerDeployed, setFooterDeployed] = useState(true)
  const cancelledOrders = orders.filter(o => o.status === "cancelled")
  const formMethods = useForm({
    defaultValues: currentOrder,
  })
  const {
    register,
    handleSubmit,
    watch,
    reset,
    setValue,
    formState: { errors },
    getValues,
    control,
  } = formMethods
  const line_items = watch("line_items")
  const shipping_price = watch("shipping_price")
  const status = watch("status")
  const onSubmit = data => {
    if (activeOrder) {
      dispatch(updateOrder({ id: activeOrder, data })).then(result => {
        console.log("dispatch submit", result.payload)
        reset(result.payload)
      })
    }
  }

  const { sendMessage, lastMessage, readyState } = useWebSocket(socketUrl, {
    share: true,
    onOpen: () => console.log("websocket opened"),
    shouldReconnect: closeEvent => true,
  })

  useEffect(() => {
    try {
      if (lastMessage !== null) {
        if (lastMessage.data) {
          const b = JSON.parse(lastMessage.data)
          if (b.event_type === "order_upsert") {
            reset(b.payload)
          }
        }
      }
    } catch (e) {
      console.log(e)
    }
    if (lastMessage) {
    }
  }, [lastMessage])

  useEffect(() => {
    if (activeOrder) {
      const order = orders.find(o => o.id == activeOrder)
      if (order) {
        reset(order)
        setCurrentOrder(order)
      }
    } else {
      console.log("should reset form")
      reset()
      setCurrentOrder(null)
    }
  }, [activeOrder])

  useEffect(() => {
    setCanCreateOrder(
      !orders.some(o => ["draft", "confirmed", "processing", "ready", "shipped"].indexOf(o.status) >= 0)
    )
  }, [orders])

  // unselect order when chat changes
  useEffect(() => {
    if (activeOrder) {
      const order = orders.find(o => o.id === activeOrder)
      if (order) {
        if (chat.id.user != order.phone) {
          setActiveOrder(null)
        }
      } else {
        setActiveOrder(null)
      }
    }
  }, [chat, activeOrder])

  // update hot totals
  useEffect(() => {
    if (line_items) {
      setProductsTotal(getProductsTotal())
    }
  }, [line_items, shipping_price, products])

  const getProductsTotal = () => {
    const prod = line_items
      .filter(x => !x._destroy)
      .reduce((partialSum, item) => {
        const product = products.find(p => p.id == item.product_id)
        if (!product) {
          return partialSum
        }
        let val = product.price * item.quantity
        if (item.discount && item.discount_type === "amount") {
          val = val - item.discount
        }
        if (item.discount && item.discount_type === "percentage") {
          const d = (parseFloat(item.discount) * val) / 100
          const result = val - d
          val = result
        }
        return partialSum + val
      }, 0)
    return prod
  }
  const handleNewOrder = () => {
    dispatch(
      createOrder({
        phone: chat.id.user,
        name: chat._k_notifyName ? chat._k_notifyName.split(" ")[0] : "",
        last_name: chat._k_notifyName ? chat._k_notifyName.split(" ")[1] : "",
      })
    ).then(({ payload }) => {
      setActiveOrder(payload.id)
      setDeployedSection("cart")
    })
  }
  const handleOrderConfirmation = () => {
    setValue("status", "confirmed")
    handleSubmit(onSubmit)()
    // dispatch(confirmOrder(activeOrder))
  }
  const handleVoidOrder = () => {
    setValue("status", "cancelled")
    dispatch(voidOrder({ id: activeOrder })).then(res => {
      setActiveOrder(null)
    })
  }
  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        {currentOrder && (
          <OrderHeader
            order={currentOrder}
            control={control}
            busy={statuses[currentOrder.id] === "loading"}
            handleBack={() => setActiveOrder(null)}
          />
        )}
        <div
          className="overflow-auto p-2 bg-secondary"
          style={{
            height: currentOrder ? `calc(100vh - ${footerDeployed ? 270 : 170}px)` : "calc(100vh - 70px)",
          }}
        >
          {!currentOrder && (
            <div>
              <OrdersList
                phone={chat.id.user}
                handleViewOrder={id => setActiveOrder(id)}
                canCreateOrder={canCreateOrder}
                handleNewOrder={handleNewOrder}
              />
              <QuickReplies from={chat._k_client} chatId={chat._k_chatid} />
              {cancelledOrders.length > 0 && (
                <div className="card bg-light mb-3">
                  <div className="card-header text-center font-weight-bold">
                    Pedidos cancelados ({cancelledOrders.length})
                  </div>
                  <div className="text-center">
                    <div className="list-group list-group-flush">
                      {cancelledOrders.map(o => (
                        <a
                          href={"#"}
                          className="list-group-item"
                          onClick={() => setActiveOrder(o.id)}
                          key={`cancelled_orders_${o.id}`}
                        >
                          #{o.number}
                        </a>
                      ))}
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}
          {currentOrder && !currentOrder.created_at && (
            <div className="text-center p-2">
              <h4 className="text-white text-uppercase">Cargando...</h4>
            </div>
          )}
          {currentOrder && products.length == 0 && currentOrder.created_at && (
            <div className="text-center p-2">
              <h4 className="text-white text-uppercase">Cargando productos...</h4>
            </div>
          )}
          {currentOrder && products.length && currentOrder.created_at && (
            <div className="">
              <div>
                {line_items.length > 0 && (
                  <div className="mb-3 d-none">
                    <SummarySection
                      productsTotal={productsTotal}
                      handleSendSummary={text => {
                        WhatsAppService.sendMessage(chat._k_client, chat.id._serialized, text).then(args => {
                          dispatch(messageAdded(args))
                        })
                      }}
                    />
                  </div>
                )}
                <div className="mb-3">
                  <CartSection
                    control={control}
                    products={products}
                    deployed={deployedSection == "cart"}
                    onAdvance={() => {
                      setDeployedSection("shipping")
                    }}
                  />
                </div>
                <div className="mb-3">
                  <ShippingSection
                    control={control}
                    products={products}
                    deployed={deployedSection == "shipping"}
                    chatId={chat.id._serialized}
                    onAdvance={() => {
                      setDeployedSection("invoice")
                    }}
                    handleSendSummary={text => {
                      WhatsAppService.sendMessage(chat._k_client, chat.id._serialized, text).then(args => {
                        dispatch(messageAdded(args))
                      })
                    }}
                  />
                </div>
                <div className="mb-3">
                  <InvoiceSection
                    deployed={deployedSection == "invoice"}
                    control={control}
                    handleSendSummary={text => {
                      WhatsAppService.sendMessage(chat._k_client, chat.id._serialized, text).then(args => {
                        dispatch(messageAdded(args))
                      })
                    }}
                    onAdvance={() => {
                      setDeployedSection("payment")
                    }}
                  />
                </div>
                <div className="mb-3">
                  <PaymentSection
                    deployed={deployedSection == "payment"}
                    onAdvance={() => {
                      setDeployedSection("")
                    }}
                    control={control}
                    handleSendSummary={text => {
                      WhatsAppService.sendMessage(chat._k_client, chat.id._serialized, text).then(args => {
                        dispatch(messageAdded(args))
                      })
                    }}
                  />
                </div>
                <div className="mb-3">
                  <CustomerSection control={control} products={products} />
                </div>
                <div>
                  <button
                    type="button"
                    onClick={() => handleOrderConfirmation()}
                    className="btn btn-success btn-block"
                    disabled={["draft", "cancelled"].indexOf(status) < 0 || line_items.length == 0}
                  >
                    {status == "confirmed" ? "Orden confirmada" : "Confirmar orden"}
                  </button>
                  <a
                    href="#"
                    onClick={() => {
                      const msg = `Pedido confirmado, muchas gracias! ${currentOrder.customer_url}`
                      WhatsAppService.sendMessage(chat._k_client, chat.id._serialized, msg).then(args => {
                        dispatch(messageAdded(args))
                      })
                    }}
                    className="btn btn-link btn-sm btn-block text-white"
                  >
                    <u>Notificar confirmación</u>
                  </a>
                  <div className="d-flex align-items-center justify-content-between mt-2">
                    <a
                      href="#"
                      disabled={statuses[currentOrder.id] === "loading" || status === "cancelled"}
                      onClick={() => {
                        if (window.confirm(`Desea cancelar la orden #${currentOrder.number}?`)) {
                          handleVoidOrder()
                        }
                      }}
                      className="btn btn-danger btn-sm text-white"
                    >
                      Cancelar orden
                    </a>
                    <a
                      href={currentOrder.edit_url}
                      className="btn btn-primary btn-sm text-white"
                      target="_blank"
                    >
                      Ver en sistema
                    </a>
                  </div>
                </div>
                <div className="mt-4 pt-2 border-top border-dark">
                  <div className="form-group">
                    <label className="text-white">
                      <small>Comentarios internos</small>
                    </label>
                    <textarea
                      {...register("internal_notes")}
                      placeholder="Escribir..."
                      className="form-control form-control-sm bg-dark text-white"
                    ></textarea>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        {currentOrder && products.length && currentOrder.created_at && (
          <OrderFooter
            order={currentOrder}
            status={status}
            statuses={statuses}
            control={control}
            busy={statuses[currentOrder.id] === "loading"}
            handleBack={() => setActiveOrder(null)}
            deployed={footerDeployed}
            handleDeploy={() => setFooterDeployed(!footerDeployed)}
            productsTotal={productsTotal}
            handleSendSummary={text => {
              WhatsAppService.sendMessage(chat._k_client, chat.id._serialized, text).then(args => {
                dispatch(messageAdded(args))
              })
            }}
            handleOrderConfirmation={() => handleOrderConfirmation()}
            handleVoidOrder={() => handleVoidOrder()}
          />
        )}
      </form>
    </FormProvider>
  )
}

Cart.propTypes = {
  chat: PropTypes.object.isRequired,
}

export default Cart
