/** @format */

const PhoneNumber = ({ number }) => {
  const m = /^(595|0)*\h*([0-9\h]+)/gm.exec(number)
  if (m) {
    return <div>0{m[2]}</div>
  }
  return <div>{number}</div>
}

export default PhoneNumber
