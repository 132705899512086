/** @format */

import React, { useState, useEffect, useRef } from "react"
import { useSelector, useDispatch } from "react-redux"

import {
  selectAllChats,
  selectFilteredChats,
  setFilterValue,
  setAccountFilterValue,
  setTextSearch,
} from "./features/chats/chats-slice"
import PhoneNumber from "./components/PhoneNumber"
import StatusToEmoji from "./components/StatusToEmoji"
import formatDate from "./lib/formatDate"
import "./ChatList.css"

const Chat = ({ chat, active, onClick }) => {
  const dateMarker = formatDate({ date: new Date(chat.timestamp * 1000) })
  return (
    <a
      href={void 0}
      className={`Chat p-1 cursor-pointer list-group-item list-group-item-action scroll-snap-start`}
      style={{
        backgroundColor: active ? "#badfb0" : "transparent",
      }}
      onClick={onClick}
    >
      <div className="row no-gutters">
        <div className="col-md-2">
          <img src="https://via.placeholder.com/50x50.png" className="img-fluid rounded-circle" />
          {chat.latest_order_status && (
            <span className={`badge mr-2 badge-secondary`}>
              <small>
                <StatusToEmoji status={chat.latest_order_status} /> #{chat.latest_order_number}
              </small>
            </span>
          )}
        </div>
        <div className="col-md-8">
          <div className="pl-2 pr-2">
            <div className="font-weight-bold text-truncate">{chat._k_notifyName || chat.id.user}</div>
            <div>
              <span className="small">
                {chat._k_client == "595993468653@c.us" ? (
                  <span className="badge badge-danger">PDL</span>
                ) : (
                  <span className="badge badge-warning">KLIP</span>
                )}
              </span>
            </div>
            <div className="small mt-1" style={{ fontSize: ".7rem" }}>
              <PhoneNumber number={chat.id.user} />
            </div>
          </div>
          <div className="pl-2 pr-2"></div>
          <div className="pl-2 pr-2 text-truncate">
            <span className="mr-2">
              {chat._k_lastMsgAck === 0 && "⌛️"}
              {chat._k_lastMsgAck === 1 && "☑️"}
              {chat._k_lastMsgAck === 2 && "☑️☑️"}
              {chat._k_lastMsgAck === 3 && "✅✅"}
            </span>
            <small>{chat._k_lastMsgSnippet}</small>
          </div>
        </div>
        <div className="col-md-2 d-flex flex-column align-items-end justify-content-between">
          <code className={`${active ? "text-white" : "text-secondary"}`} style={{ fontSize: ".7rem" }}>
            {dateMarker}
          </code>
          <span>{chat._k_hasLocation && "📍"}</span>
          {chat.unreadCount > 0 && (
            <span className="badge ml-1 badge-success badge-pill">{chat.unreadCount}</span>
          )}
        </div>
      </div>
    </a>
  )
}

const ChatList = ({ handleClick, currentChatId }) => {
  const dispatch = useDispatch()
  const chatsStatus = useSelector(state => state.chats.status)
  const chats = useSelector(selectFilteredChats)
  const statusFilter = useSelector(state => state.chats.filterValue)
  const accountFilter = useSelector(state => state.chats.accountFilterValue)
  const textSearch = useSelector(state => state.chats.textSearch)
  const [filter, setFilter] = useState("")
  const scrollRef = useRef(null)
  const handleFilterChange = e => {
    dispatch(setFilterValue(e.target.value))
  }
  const handleAccountFilterChange = e => {
    dispatch(setAccountFilterValue(e.target.value))
  }

  useEffect(() => {
    // if (scrollRef) {
    //   scrollRef.current.scrollTop = 0
    // }
  }, [statusFilter, accountFilter, textSearch, chats])

  return (
    <div className={`ChatList row`} style={{}}>
      <div className="col">
        <div className="bg-secondary text-white shadow">
          <div className="p-3">
            <div className="form-group row">
              <label className="col-sm-3 col-form-label">
                <b>Marca</b>
              </label>
              <div className="col">
                <select className="form-control" value={accountFilter} onChange={handleAccountFilterChange}>
                  {false && <option value={""}>Todas</option>}
                  <option value={"595993468653@c.us"}>Pedacito de Luna</option>
                  <option value={"595992254122@c.us"}>Klip</option>
                </select>
              </div>
            </div>
            <div className="form-group row d-none">
              <label className="col-sm-3 col-form-label">
                <b>Filtro</b>
              </label>
              <div className="col">
                <select className="form-control" value={statusFilter} onChange={handleFilterChange}>
                  <option>Todos los chats</option>
                  <option value={"location_without_order"}>🚨 Prioritarios</option>
                  <option value={"unread"}>🟢 No leídos</option>
                  <option value={"draft"}>🛒 Carritos</option>
                  <option value={"confirmed"}>💰 Confirmados</option>
                  <option value={"processing"}>⚙️ En Producción</option>
                  <option value={"ready"}>📦 Empacados</option>
                  <option value={"shipped"}>✈️ Despachados</option>
                  <option value={"delivered"}>✅ Entregados</option>
                  <option value={"cancelled"}>💀 Cancelados</option>
                </select>
              </div>
            </div>
            <div className="form-group row mb-0">
              <label className="col-sm-3 col-form-label">
                <b>Buscar</b>
              </label>
              <div className="col">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Nombre, cel o # de pedido"
                  value={textSearch}
                  onChange={e => dispatch(setTextSearch(e.target.value))}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="list-group overflow-auto y-mandatory" ref={scrollRef}>
          {chats.map(chat => (
            <Chat
              key={`${chat.id._serialized}_${chat._k_client}`}
              chat={chat}
              onClick={() => handleClick(chat)}
              active={currentChatId == `${chat.id._serialized}_${chat._k_client}`}
            />
          ))}
        </div>
      </div>
    </div>
  )
}

export default ChatList
