/** @format */

import { createSlice, createEntityAdapter, nanoid, createAsyncThunk, createSelector } from "@reduxjs/toolkit"

import SalesService from "../../services/klipsales.service.js"
import WhatsAppService from "../../services/whatsapp.service.js"
import { formatter } from "../../components/Guaranies"
import {
  fetchMessages,
  selectAllMessages,
  selectMessagesForChat,
  messageAdded,
} from "../messages/messages-slice"

const productsAdapter = createEntityAdapter({
  selectId: product => product.id,
  sortComparer: (a, b) => b.whatsapp_views_count - a.whatsapp_views_count,
})

const initialState = productsAdapter.getInitialState({
  status: "idle",
  error: null,
})

const productsSlice = createSlice({
  name: "products",
  initialState,
  reducers: {
    hydrate: (state, action) => {
      // do not do state = action.payload it will not update the store
      return action.payload
    },
    // receive
    // received: (state, action) => {
    //   state[action.payload.id] = action.payload
    // },
  },
  extraReducers(builder) {
    builder
      .addCase("@@INIT", (state, action) => {
        state.status = "idle"
      })
      .addCase(fetchProducts.pending, (state, action) => {
        state.status = "loading"
      })
      .addCase(fetchProducts.fulfilled, (state, action) => {
        state.status = "succeeded"
        productsAdapter.upsertMany(state, action.payload)
      })
      .addCase(fetchProducts.rejected, (state, action) => {
        state.status = "failed"
        state.error = action.error.message
      })
  },
})

export const fetchProducts = createAsyncThunk(
  "products/fetchProducts",
  async ({ page = 1 } = {}, thunkAPI) => {
    const response = await SalesService.listProducts(page)
    if (response.length && page < 3) {
      thunkAPI.dispatch(fetchProducts({ page: page + 1 }))
    }
    return response
  }
)

export const sendDefaultReply = createAsyncThunk(
  "products/sendDefaultReply",
  async ({ from, chatId, productId }, thunkAPI) => {
    const product = selectById(thunkAPI.getState(), productId)
    const messages = [
      {
        type: "media",
        payload: product.image_url,
      },
      {
        type: "text",
        payload: `${product.name}\n\n*${formatter.format(product.price)}*`,
      },
    ]
    return messages.reduce((p, message, currentIndex, array) => {
      return p.then(() => {
        switch (array[currentIndex].type) {
          case "text":
            return WhatsAppService.sendMessage(from, chatId, array[currentIndex].payload).then(args => {
              thunkAPI.dispatch(messageAdded(args))
            })
            break
          case "media":
            return WhatsAppService.sendImage(from, chatId, array[currentIndex].payload).then(args => {
              thunkAPI.dispatch(messageAdded(args))
              return
            })
            break
          default:
            return Promise.resolve()
        }
      })
    }, Promise.resolve())
  }
)

export const {
  selectAll: selectAllProducts,
  selectById,
  // selectIds: selectMessageIds,
  // Pass in a selector that returns the posts slice of state,
} = productsAdapter.getSelectors(state => state.products)

// export const selectOrdersForChat = createSelector(
//   [selectAllProducts, (state, phone) => phone],
//   (items, phone) => {
//     const filtered = items.filter(order => {
//       return order.phone === phone
//     })
//     return filtered
//   }
// )
export const { received } = productsSlice.actions
export default productsSlice.reducer
