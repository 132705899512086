/** @format */

/** @format */

import axios from "axios"
const MAPS_API_KEY = "AIzaSyCx5td5Pv3zoJFQXeHIH4tv4_2t6oEIIVY"

const http = axios.create({
  // baseURL: "http://apihosanna.contentor.io/v1",
  // baseURL: "http://klipsales.local:3000/",
  baseURL: "https://maps.googleapis.com/",
  // https://maps.googleapis.com/maps/api/geocode/json?latlng=-25.3481776,-57.622305&sensor=true&key=AIzaSyCx5td5Pv3zoJFQXeHIH4tv4_2t6oEIIVY
  headers: {
    "Content-type": "application/json",
  },
})


// const alwaysAppendQs = "?installation_id=2c2528d0-4008-49be-bbee-e005eff48cfb"
// const alwaysAppendQs = "?installation_id=135b4a9b-76d3-4b0b-adf2-9a6f6e765e58"

class GoogleMapsService {
  // getAll() {
  //   try {
  //     return http.get("/articles/list" + alwaysAppendQs).then(res => res.data["response"])
  //   } catch (e) {
  //     console.log(e)
  //   }
  // }

  // update(id, data) {
  //   return http.patch(`/articles/${id}/update` + alwaysAppendQs, data).then(res => res.data["response"])
  // }

  getData(lat,long) {
    return http.get(`/maps/api/geocode/json?latlng=${lat},${long}&sensor=true&key=${MAPS_API_KEY}`).then(res => res.data)
  }

  // show(id) {
  //   return http.get(`/feed_blueprints/${id}` + alwaysAppendQs).then(res => res.data["response"])
  // }
}

export default new GoogleMapsService()
