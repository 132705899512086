/** @format */

import React, { useState, useEffect, useRef, useCallback, useMemo } from "react"
import { useForm, useWatch, useFieldArray, useFormContext } from "react-hook-form"
import { useSelector, useDispatch } from "react-redux"
import PropTypes from "prop-types"

import SalesService from "../services/klipsales.service.js"
import { fetchLocations, selectLocationsForChat } from "../features/locations/locations-slice"
import { fetchCities, selectAllCities, selectAllCitiesWithReplies } from "../features/cities/cities-slice"
import { fetchProducts, selectAllProducts, sendDefaultReply } from "../features/products/products-slice"
import { fetchReplies, sendReply, selectNonProductReplies } from "../features/replies/replies-slice"
import Guaranies from "../components/Guaranies"
import OrdersList from "../components/OrdersList"

const QuickReplies = ({ from, chatId }) => {
  const dispatch = useDispatch()
  const [textSearch, setTextSearch] = useState("")
  const replies = useSelector(selectNonProductReplies)
  const products = useSelector(selectAllProducts)
  const cities = useSelector(selectAllCitiesWithReplies)
  const refreshLists = () => {
    dispatch(fetchReplies())
    dispatch(fetchCities())
    dispatch(fetchProducts())
  }
  useEffect(() => {
    refreshLists()
  }, [])
  const sendProductReply = product => {
    setTextSearch("")
    SalesService.bumpProductWAViewCount(product.id)

    if (product.reply_id) {
      dispatch(sendReply({ from, chatId, replyId: product.reply_id }))
    } else {
      dispatch(sendDefaultReply({ from, chatId, productId: product.id }))
    }
  }
  const sendCityReply = city => {
    setTextSearch("")
    // SalesService.bumpProductWAViewCount(product.id)
    if (city.reply_id) {
      dispatch(sendReply({ from, chatId, replyId: city.reply_id }))
    }
  }
  return (
    <div className="card border-light mb-3">
      <div className="card-header bg-light text-uppercase font-weight-bold">Respuestas rapidas</div>
      <div className="card-body">
        <div className="form-group">
          <input
            className="form-control form-control-sm"
            placeholder="Producto, ciudad o rpta rapida"
            value={textSearch}
            onChange={e => setTextSearch(e.target.value)}
          />
        </div>
        <p className="d-none">Respuestas:</p>
        {textSearch && (
          <div>
            {replies.length > 0 &&
              replies
                .filter(r =>
                  r.title
                    .toLowerCase()
                    .normalize("NFD")
                    .replace(/[\u0300-\u036f]/g, "")
                    .includes(
                      textSearch
                        .toLowerCase()
                        .normalize("NFD")
                        .replace(/[\u0300-\u036f]/g, "")
                    )
                )
                .map(r => {
                  return (
                    <button
                      type="button"
                      key={`quick_reply_${r.id}`}
                      className="btn btn-primary btn-sm mr-1 mb-1"
                      onClick={() => {
                        dispatch(sendReply({ from, chatId, replyId: r.id }))
                        setTextSearch("")
                      }}
                    >
                      {r.title}
                    </button>
                  )
                })}
          </div>
        )}
        {!textSearch &&
          replies.slice(0, 10).map(r => {
            return (
              <button
                type="button"
                key={`quick_reply_${r.id}`}
                className="btn btn-primary btn-sm mr-1 mb-1"
                onClick={() => {
                  dispatch(sendReply({ from, chatId, replyId: r.id }))
                  setTextSearch("")
                }}
              >
                {r.title}
              </button>
            )
          })}
        <p className="d-none">Productos</p>
        {textSearch && (
          <div className="list-group overflow-auto mb-2" style={{ maxHeight: "40vh" }}>
            {products
              .filter(p =>
                p.name
                  .toLowerCase()
                  .normalize("NFD")
                  .replace(/[\u0300-\u036f]/g, "")
                  .includes(
                    textSearch
                      .toLowerCase()
                      .normalize("NFD")
                      .replace(/[\u0300-\u036f]/g, "")
                  )
              )
              .map(p => (
                <div
                  key={`quick_reply_product_${p.id}`}
                  className="list-group-item list-group-item-action"
                  style={{ cursor: "pointer" }}
                  onClick={() => sendProductReply(p)}
                >
                  <div className="d-flex">
                    <img src={p.image_url} alt={p.name} title={p.name} className="mr-3" width="50px" />
                    <small>
                      <b>{p.name}</b>
                    </small>
                  </div>
                </div>
              ))}
          </div>
        )}
        {!textSearch && (
          <div className="row no-gutters mb-2">
            {products
              // .filter(p => p.reply_id && p.has_variants)
              .filter(p => p.reply_id)
              .slice(0, 13)
              .map(p => (
                <div
                  key={`quick_reply_product_${p.id}`}
                  className="col-3"
                  style={{ cursor: "pointer" }}
                  onClick={() => sendProductReply(p)}
                >
                  <img src={p.image_url} alt={p.name} title={p.name} className="img-thumbnail" />
                </div>
              ))}
          </div>
        )}
        <p className="d-none">Ciudades:</p>
        {textSearch && (
          <div>
            {cities.length > 0 &&
              cities
                .filter(c =>
                  c.name
                    .toLowerCase()
                    .normalize("NFD")
                    .replace(/[\u0300-\u036f]/g, "")
                    .includes(
                      textSearch
                        .toLowerCase()
                        .normalize("NFD")
                        .replace(/[\u0300-\u036f]/g, "")
                    )
                )
                .map(c => {
                  return (
                    <button
                      type="button"
                      key={`quick_reply_c_t${c.id}`}
                      className="btn btn-primary btn-sm mr-1 mb-1"
                      onClick={() => {
                        dispatch(sendReply({ from, chatId, replyId: c.reply_id }))
                        setTextSearch("")
                      }}
                    >
                      {c.name}
                    </button>
                  )
                })}
          </div>
        )}
        {!textSearch &&
          cities.slice(0, 20).map(c => {
            return (
              <button
                type="button"
                key={`quick_reply_c_${c.id}`}
                className="btn btn-primary btn-sm mr-1 mb-1"
                onClick={() => {
                  dispatch(sendReply({ from, chatId, replyId: c.reply_id }))
                  setTextSearch("")
                }}
              >
                {c.name}
              </button>
            )
          })}
      </div>
      <div className="card-footer text-muted text-right">
        <a
          href={void 0}
          className="card-link text-secondary text-decoration-underline cursor-pointer"
          onClick={refreshLists}
        >
          <small>
            <u>Refrescar lista</u>
          </small>
        </a>
      </div>
    </div>
  )
}

export default QuickReplies
