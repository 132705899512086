/** @format */

import { configureStore } from "@reduxjs/toolkit"

import locationsReducer from "../features/locations/locations-slice"
import chatsReducer from "../features/chats/chats-slice"
import ordersReducer from "../features/orders/orders-slice"
import productsReducer from "../features/products/products-slice"
import repliesReducer from "../features/replies/replies-slice"
import messagesReducer from "../features/messages/messages-slice"
import citiesReducer from "../features/cities/cities-slice"

const KEY = "redux"
export function loadState() {
  try {
    const serializedState = localStorage.getItem(KEY)
    if (!serializedState) return undefined
    return JSON.parse(serializedState)
  } catch (e) {
    return undefined
  }
}

export async function saveState(state) {
  try {
    const serializedState = JSON.stringify(state)
    localStorage.setItem(KEY, serializedState)
  } catch (e) {
    // Ignore
  }
}
const store = configureStore({
  reducer: {
    cities: citiesReducer,
    locations: locationsReducer,
    chats: chatsReducer,
    orders: ordersReducer,
    products: productsReducer,
    replies: repliesReducer,
    messages: messagesReducer,
  },
  preloadedState: loadState(),
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      immutableCheck: false,
      // immutableCheck: {
      // ignoredPaths: ['ignoredPath', 'ignoredNested.one', 'ignoredNested.two'],
      // },
    }),
  // devTools: {
  //   // options as if you were setting it up by hand
  //   // https://github.com/zalmoxisus/redux-devtools-extension/blob/master/docs/API/Arguments.md#windowdevtoolsextensionconfig
  //   stateSanitizer: (state) => state.data ? { ...state, data: '<<LONG_BLOB>>' } : state
  // }
})
store.subscribe(
  // we use debounce to save the state once each 800ms
  // for better performances in case multiple changes occur in a short time
  // debounce(() => {
  () => saveState(store.getState())
  // }, 800)
)

export default store
