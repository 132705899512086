/** @format */

import axios from "axios"

export default axios.create({
  baseURL: "https://wa.klipstore.com:5000",
  headers: {
    "Content-type": "application/json",
  },
})
