/** @format */

import React, { useState, useEffect, useRef, useCallback, useMemo } from "react"
import { useForm, useWatch, useFieldArray, useFormContext } from "react-hook-form"
import { useSelector, useDispatch } from "react-redux"
import PropTypes from "prop-types"

import SalesService from "../services/klipsales.service.js"
import { fetchLocations, selectLocationsForChat } from "../features/locations/locations-slice"
import {
  fetchOrders,
  selectOrdersForChat,
  createOrder,
  updateOrder,
  selectOrderById,
  voidOrder,
  confirmOrder,
  selectOrderTotals,
} from "../features/orders/orders-slice"
import Guaranies from "../components/Guaranies"
import OrdersList from "../components/OrdersList"

const Invoice = ({ control, deployed, onAdvance, handleSendSummary }) => {
  const [visible, setVisible] = useState(deployed)
  const { watch, register } = useFormContext()
  const tax_id = watch("tax_id")
  return (
    <div className="card border-light">
      <div
        className="card-header bg-light text-uppercase font-weight-bold cursor-pointer d-flex justify-content-between"
        onClick={() => setVisible(!visible)}
      >
        {!tax_id && "Factura"}
        {tax_id && "Con Factura"}
        <div className="">{tax_id ? "✅" : "⬜️"}</div>
      </div>
      <div className={`card-body ${deployed || visible ? "" : "d-none"}`}>
        <div className="text-right">
          <a
            href={"#"}
            onClick={() => {
              handleSendSummary("Necesitarías factura?")
            }}
          >
            <small>
              <u>¿Desea factura?</u>
            </small>
          </a>
        </div>
        <div className="form-group">
          <label>Razón Social</label>
          <input {...register("invoice_name")} placeholder={"Juan Perez"} className="form-control" />
        </div>
        <div className="form-group">
          <label>RUC</label>
          <input {...register("tax_id")} placeholder={"0.000.000-0"} className="form-control" />
        </div>
      </div>
      <div
        className={`card-footer text-muted justify-content-between ${
          deployed || visible ? "d-flex" : "d-none"
        }`}
      >
        <a
          href={void 0}
          className="card-link text-secondary text-decoration-underline"
          onClick={() => {
            alert("Hacer factura manualmente")
          }}
        >
          🖨 <u>Facturar</u>
        </a>
        <button
          type="button"
          className={`btn btn-success btn-sm ${!tax_id && "disabled"}`}
          onClick={() => {
            setVisible(false)
            onAdvance()
          }}
          disabled={!tax_id}
        >
          Listo
        </button>
      </div>
    </div>
  )
}

export default Invoice
