/** @format */

import React, { useState, useEffect } from "react"

import logo from "./logo.svg"
import GoogleMapsService from "./services/googlemaps.service"
import Location from "./components/messagetypes/Location"
import Chat from "./components/messagetypes/Chat"
import Vcard from "./components/messagetypes/Vcard"
import CallLog from "./components/messagetypes/CallLog"
import Image from "./components/messagetypes/Image"
import Audio from "./components/messagetypes/Audio"
import formatDate from "./lib/formatDate"
import "./Message.css"

const Message = args => {
  const [isBlob, setIsBlob] = useState(undefined)
  const dateMarker = formatDate({ date: new Date(args.timestamp * 1000), useTimeForPastDates: true })

  if (["e2e_notification", "protocol"].indexOf(args.type) >= 0) {
    return <div />
  }
  return (
    <div className={`Message2 flex-column ${args.fromMe ? "mine" : ""}`}>
      {args.type === "chat" && <Chat args={args} />}
      {args.type === "vcard" && <Vcard args={args} />}
      {args.type === "call_log" && <CallLog args={args} />}
      {args.type === "location" && <Location args={args} />}
      {args.type === "image" && <Image args={args} />}
      {args.type === "video" && <Image args={args} />}
      {args.type === "ptt" && <Audio args={args} />}
      <div className="message-time">
        <small>
          <code className="text-secondary" style={{ whiteSpace: "nowrap" }}>
            {dateMarker}
          </code>
        </small>
      </div>
    </div>
  )
  return (
    <div className={`Message ${args.fromMe ? "mine" : ""}`}>
      [{args.type}]<br />
      <div className="message-time">
        <small className="font-weight-bold">
          <code className="text-secondary">{dateMarker}</code>
        </small>
      </div>
      {false && isBlob == false && !args._data.ctwaContext && args.type != "image" && args.body}
      {args.type == "image" ||
        (isBlob == true && (
          <figure className="figure mb-0">
            {args.fromMe ? (
              <img
                src={"data:image/png;base64," + args._data.body}
                style={{ maxWidth: "200px" }}
                className="figure-img img-fluid rounded"
                alt="..."
              />
            ) : (
              <img
                src={`http://wa.klipstore.com/downloads/${args.id.remote}-${args.id.id}.${
                  args._data.mimetype ? args._data.mimetype.split("/")[1] : ""
                }`}
                style={{ maxWidth: "200px" }}
                className="figure-img img-fluid rounded"
                alt="..."
              />
            )}
            <p className="mb-0">{JSON.stringify(args.body, null, 2)}</p>
            {args._data.mimetype && ["mp4", "mov"].indexOf(args._data.mimetype.split("/")[1]) >= 0 && (
              <small className="mb-0 font-weight-bold">[VIDEO]</small>
            )}
            asdf
          </figure>
        ))}
      {args._data.ctwaContext && (
        <div className="card">
          <div className="row no-gutters">
            <div className="col-md-3">
              <img
                // src={"data:image/png;base64," + args._data.ctwaContext.thumbnail}
                src={args._data.ctwaContext.thumbnailUrl}
                className="card-img-top"
                alt="..."
              />
              fds
            </div>
            <div className="col-md-9">
              <div className="card-body">
                <h5 className="card-title">{args.body}</h5>
                <p className="card-text">
                  {args.body}
                  <br />
                  <small>{args._data.ctwaContext.description}</small>
                </p>
                <a href={void 0} className="btn btn-sm btn-dark">
                  {args._data.ctwaContext.title}
                </a>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default Message
