/** @format */

import React, { useState, useEffect } from "react"

import logo from "./logo.svg"
import "./Composer.css"

const Composer = ({ onSubmit }) => {
  const [text, setText] = useState()
  const handleSubmit = () => {
    onSubmit(text)
    setText("")
  }
  return (
    <form className={`Composer bg-secondary p-2 d-flex flex-row`}>
      <textarea
        className="form-control bg-light border-0"
        onChange={e => setText(e.target.value)}
        onKeyDown={evt => {
          const keyCode = evt.keyCode
          if (keyCode === 13) {
            evt.preventDefault()
            handleSubmit()
          }
        }}
        value={text}
        autoFocus
        rows={1}
        placeholder="Escribir mensaje..."
      ></textarea>

      <button type="button" className="align-self-end btn btn-lg text-light" onClick={handleSubmit}>
        <i className="fa fa-send"></i>
      </button>
    </form>
  )
}

export default Composer
