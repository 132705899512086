/** @format */

import React, { useState, useEffect } from "react"
import { CopyToClipboard } from "react-copy-to-clipboard"

import vCard from "vcard-parser"

const Vcard = ({ args }) => {
  var card = vCard.parse(args.body)
  console.log("vCard.generate(card)")
  console.log(card)
  return (
    <div className={`Message bg-light text-whit ${args.fromMe ? "mine" : ""}`}>
      <div className="row">
        <div className="col flex-grow-0">
          <img
            src={`https://eu.ui-avatars.com/api/?name=${card.fn.map(f => f.value).join("+")}&size=80`}
            className="rounded-circle"
          />
        </div>
        <div className="col flex-grow pl-0">
          <div className="h6">{card.fn.map(f => f.value).join("")}</div>
          <div className="">
            <CopyToClipboard
              text={card.tel.map(f => f.value).join(" ")}
              className="copy-to-clipboard text-primary"
              onCopy={() => alert("Copiado al portapapeles")}
            >
              <small>
                {card.tel.map(f => f.value).join("")}
                <i className="fa fa-clipboard ml-1"></i>
              </small>
            </CopyToClipboard>
          </div>
          <hr className="mt-1 mb-1" />
          <CopyToClipboard
            text={card.tel.map(f => f.value).join(" ")}
            onCopy={() => alert("Copiado al portapapeles")}
            className="btn btn-sm btn-outline-dark"
          >
            <div>Copiar nro</div>
          </CopyToClipboard>
        </div>
      </div>
    </div>
  )
}

export default Vcard
