/** @format */

const messageToSnippet = ({ message, receivedLocationName }) => {
  if (!message) return ""
  let result = ""
  switch (message.type) {
    case "location":
      result = `📍 ${receivedLocationName}`
      break
    case "image":
      result = `📷 Imagen`
      break
    case "image":
      result = `📷 Imagen`
      break
    case "video":
      result = `📷 Video`
      break
    case "call_log":
      result = message._data.subtype === "miss" ? `📞 Llamada perdida` : `📞 Llamada`
      break
    case "ptt":
      result = `🎤 Audio`
      break
    default:
      result = message.body
  }
  return result
}
module.exports = messageToSnippet
