/** @format */

import React, { useState, useEffect, useRef, useCallback, useMemo } from "react"
import { useForm, useWatch, useFieldArray, useFormContext } from "react-hook-form"
import { useSelector, useDispatch } from "react-redux"
import PropTypes from "prop-types"

import SalesService from "../services/klipsales.service.js"
import { fetchLocations, selectLocationsForChat } from "../features/locations/locations-slice"
import {
  fetchOrders,
  selectOrdersForChat,
  createOrder,
  updateOrder,
  selectOrderById,
  voidOrder,
  confirmOrder,
  selectOrderTotals,
} from "../features/orders/orders-slice"
import { selectAllProducts } from "../features/products/products-slice"
import Guaranies from "../components/Guaranies"
import OrdersList from "../components/OrdersList"

const CustomerSection = ({ control, products, deployed }) => {
  const { watch, register } = useFormContext()
  const [visible, setVisible] = useState(deployed)
  return (
    <div className="card border-light">
      <div
        className="card-header bg- text-uppercase font-weight-bold d-flex justify-content-between"
        style={{ cursor: "pointer" }}
        onClick={() => setVisible(!visible)}
      >
        CLIENTE
      </div>
      <div className={`card-body ${visible || deployed ? "" : "d-none"}`}>
        <div className="form-group">
          <label>Nombre</label>
          <input {...register("name")} className="form-control" />
        </div>
        <div className="form-group">
          <label>Apellido</label>
          <input {...register("last_name")} className="form-control" />
        </div>
        <div className="form-group">
          <label>Celular</label>
          <input {...register("phone")} className="form-control" disabled />
        </div>
      </div>
    </div>
  )
}

export default CustomerSection
