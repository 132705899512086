/** @format */

import React, { useState, useEffect } from "react"

const CallLog = ({ args }) => {
  const date = new Date(args.timestamp * 1000)
  const time = `${String(date.getHours()).padStart(2, "0")}:${String(date.getMinutes()).padStart(2, "0")}`
  let label = ""
  let bg = ""
  switch (args._data.subtype) {
    case "miss":
      label = "Llamada perdida"
      bg = "bg-danger text-white"
      break
  }
  return (
    <div className={`Message call-log ${args.fromMe ? "mine" : ""} ${bg}`}>
      <div>
        {label} a las {time}
      </div>
    </div>
  )
}

export default CallLog
